import React, { useState, useEffect } from 'react';
import mammoth from 'mammoth';
import { getApiUrl } from '../environement/env';
import Loader from './Loader';

const WordViewer = (fileUrl) => {
    const [rawText, setRawText] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const extractTextFromDocx = async () => {
            setLoading(true);
            try {
                // Chemin du fichier dans le dossier public
                const filePath = `${getApiUrl()}/api/fetch-doc/${fileUrl.fileUrl}`;
                // Fetch le fichier pour obtenir un ArrayBuffer
                const response = await fetch(filePath);
                if (!response.ok) {
                    throw new Error(`Erreur lors du chargement du fichier : ${response.statusText}`);
                }
                const arrayBuffer = await response.arrayBuffer();

                // Extraction du texte brut avec Mammoth.js
                const { value } = await mammoth.convertToHtml({ arrayBuffer });
                setRawText(value); // Mettre à jour le texte brut dans l'état
            } catch (err) {
                setError(err.message);
                console.error('Erreur lors de l\'extraction du texte :', err);
            }
            setLoading(false);
        };

        extractTextFromDocx();
    }, []);

    return (
        <div>

            {
                loading ? <Loader/> :(
            error ? (
                <div className="error">{error}</div>
            ) : (
                <div
                    className="word-content"
                    dangerouslySetInnerHTML={{ __html: rawText }}
                />
            )) }
        </div>
    );
};

export default WordViewer;
