import React from 'react';

const ShareIcons = (facebook,linkedin,youtube) => {
    return (
        <div className="share-icons d-flex align-items-center mt-4">
            <span className="me-3">Partager :</span>
            <a href="https://www.facebook.com" className="social-icon me-3 green" target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f"></i> 
                    </a>
                    <a href="https://www.linkedin.com" className="social-icon me-3 green" target="_blank" rel="noreferrer">
                        <i className="fab fa-linkedin-in"></i> 
                    </a>
                    <a href="https://www.youtube.com" className="social-icon me-3 green" target="_blank" rel="noreferrer">
                        <i className="fab fa-youtube"></i> 
                    </a>
        </div>
    );
};

export default ShareIcons;
