import React,{ useState } from 'react';
import PopupAlert from './PopupAlert/PopupAlert';
import Contacts from '../services/Contacts';

const Devis = () => {
    const [formData, setFormData] = useState({
        nom: "",
        email: "",
        telephone: "",
        budget: "",
        message: "",
      });
      const [isSubmitting, setIsSubmitting] = useState(false);
      const [errorMessage, setErrorMessage] = useState(null);
      const [errors, setErrors] = useState({});
      const [successMessage, setSuccessMessage] = useState(null);
      const [loading, setLoading] = useState(true);
      const [alert, setAlert] = useState(null);

    const contactService = new Contacts();
    const triggerAlert = (type, message) => {
        setAlert({ type, message });
        setTimeout(() => setAlert(null), 10000); 
    }
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrorMessage(null);
        setSuccessMessage(null);
    
        console.log(formData)
        try {
          const response = await contactService.demandeDevis(formData);
          //  console.log(response);
          if (response?.message === 'Devis request submitted') {
          setSuccessMessage("Votre demande a été envoyée avec succès!");
          triggerAlert('success', 'Votre demande a été envoyée avec succès!')
          setFormData({
            nom: "",
            email: "",
            telephone: "",
            budget: "",
            message: "",
          });
        } else {
          setErrors(response?.errors || {});
        }
        } catch (error) {
          setErrorMessage("Une erreur est survenue. Veuillez réessayer.");
          triggerAlert('error', 'Une erreur est survenue. Veuillez réessayer.')
          
        } finally {
          setIsSubmitting(false); 
        }
      };
    return (
        <>
        <div className='container-fluid devis'>
            <div className='row'>
                <div className='col-md-6'>
                    <h1 className='mb-3'>Notre <span className='green'>engagement </span> envers vous</h1>
                    <p className='text-justify'>Chez V_CAM SARL, nous accordons une importance capitale à la qualité des services que nous offrons à nos clients et partenaires. Notre mission prioritaire est d’accompagner stratégiquement et opérationnellement les entreprises porteuses de projets à forte valeur ajoutée ou celles qui désirent amorcer leur transformation numérique. Grâce à notre engagement envers l’excellence, nous établissons des relations privilégiées et durables avec nos collaborateurs.
                    Nous vous proposons une approche participative en quatre étapes, conçue pour sécuriser, valoriser votre projet ou augmenter votre chiffre d’affaires. Cette démarche débute par une analyse approfondie et précise de votre activité et de vos objectifs fonctionnels, afin de garantir une réponse parfaitement adaptée à vos besoins.
                    </p>
                </div>
                <div className='col-md-6'>
                    <h4 className='mb-4'>Demander un devis</h4>
                    <form className='form-devis' onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='form-group col-md-12 mb-3'>
                                {/* <label className='mb-2' htmlFor='nom'>
                                    Nom<span className='text-danger'>*</span>/Entreprise<span className='text-danger'>*</span>
                                </label> */}
                                <input 
                                    type='text' 
                                    required 
                                    name='nom' 
                                    className='form-control' 
                                    id='nom' 
                                    placeholder='Nom* / Entreprise*'
                                    style={{ height: '65px' }} 
                                    value={formData.nom}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form-group col-md-6 mb-3'>
                                {/* <label className='mb-2' htmlFor='email'>
                                    Adresse Email<span className='text-danger'>*</span>
                                </label> */}
                                <input 
                                    type='email' 
                                    required 
                                    name='email' 
                                    className='form-control' 
                                    id='email' 
                                    placeholder='Email*' 
                                    style={{ height: '65px' }}
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form-group col-md-6 mb-3'>
                                {/* <label className='mb-2' htmlFor='telephone'>
                                    Téléphone<span className='text-danger'>*</span>
                                </label> */}
                                <input 
                                    type='text' 
                                    required 
                                    name='telephone' 
                                    className='form-control' 
                                    id='telephone' 
                                    placeholder='Téléphone*'
                                    style={{ height: '65px' }}
                                    value={formData.telephone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form-group col-md-12 mb-3'>
                                {/* <label className='mb-2' htmlFor='nom'>
                                    Nom<span className='text-danger'>*</span>/Entreprise<span className='text-danger'>*</span>
                                </label> */}
                                <input 
                                    type='text' 
                                    required 
                                    name='budget' 
                                    className='form-control' 
                                    id='budget' 
                                    placeholder='Budget*'
                                    style={{ height: '65px' }} 
                                    value={formData.budget}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='form-group col-md-12 mb-3'>
                                {/* <label className='mb-2' htmlFor='message' >Message</label> */}
                                <textarea 
                                    name='message' 
                                    className='form-control' 
                                    id='message' 
                                    placeholder='Entrez votre message...'
                                    style={{ height: '125px' }}
                                    value={formData.message}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>
                        <button type="submit" className={`btn-submit ${isSubmitting ? 'disabled' : ''}`} disabled={isSubmitting}>
                            {isSubmitting ? "Envoi en cours..." : "Envoyer la demande"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
        {alert && (
            <PopupAlert
              type={alert.type}
              message={alert.message}
              onClose={() => setAlert(null)}
            />
          )}
        </>
    );
};

export default Devis;
