import React, { useEffect, useState } from 'react';
import './PopupAlert.css';

const PopupAlert = ({ type, message, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) onClose();
    }, 10000); // Disparaît après 10 secondes

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!visible) return null;

  return (
    <div className={`popup-alert ${type}`}>
      <span>{message}</span>
    </div>
  );
};

export default PopupAlert;
