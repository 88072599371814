import React from 'react';
import CallToAction from '../../../components/calltoaction';
import Testimonies from '../../accueil/testimonies';
import PricingPlans from '../../../components/princingplans/princingplan';
import FonctionsPrincipales from '../../../components/FonctionsPrincipales/FonctionsPrincipales';

const ServiceEmail = () => {
  const features = [
    { id: 1, title: "IMAP", description: "Le protocole IMAP (Internet Message Access Protocol) vous offre un accès à vos e-mails à tout moment, où que vous soyez, et depuis n’importe quel appareil. Vos messages sont conservés sur des serveurs situés dans des data centers ultra sécurisés et restent constamment synchronisés en temps réel pour une mise à jour instantanée." },
    { id: 2, title: "Sans publicité", description: "À la différence des services gratuits, les boîtes email IONOS sont entièrement dépourvues de publicités. Cette approche garantit une expérience utilisateur fluide et agréable, vous permettant de lire et rédiger vos messages sans être dérangé par des annonces non sollicitées." },
    { id: 3, title: "Transfert d'e-mails", description: "Grâce à la fonctionnalité de transfert d'e-mails, vos messages entrants peuvent être automatiquement redirigés vers une autre adresse existante, y compris chez un fournisseur tiers, sans nécessiter de connexion à vos boîtes de réception V_CAM. Vous pouvez modifier ou désactiver cette redirection à tout moment." },
    { id: 4, title: "Anti-spam et protection antivirus", description: "Nos filtres anti-spam avancés assurent une protection efficace de vos boîtes email contre les messages indésirables. Selon le pack sélectionné, une protection antivirus renforcée est également disponible pour sécuriser davantage vos échanges. Hébergées sur nos serveurs hautement sécurisés, vos données bénéficient d’une protection optimale contre les accès non autorisés et les éventuelles défaillances." },
    { id: 6, title: "Archivage d’emails", description: "La fonctionnalité d’archivage d’e-mails vous offre la possibilité de conserver en toute sécurité vos messages et pièces jointes dans des data centers certifiés situés en Europe. Vous pouvez les restaurer à tout moment, que ce soit pour des besoins juridiques ou pour d'autres raisons." },
    { id: 5, title: "Adresse email basée sur votre domaine", description: "Que ce soit pour un usage personnel ou professionnel, disposer d'une adresse e-mail personnalisée associée à votre propre nom de domaine est un choix judicieux. Les adresses personnalisées reflètent davantage de sérieux et renforcent la confiance de vos interlocuteurs. C’est pourquoi les packs Email V_CAM incluent un domaine pour vous permettre de créer une adresse à votre image." },
  ];
  const plansData = [
    {
      id: 1,
      title: 'Email Basic 1',
      storage: '12 Go',
      emails: 'environ 60 000 emails',
      price: 1.5,
      priceTTC: 3,
      features: [
        '1 boîte email de 2 Go, extensible à tout moment',
        '1 domaine inclus',
        'Sans publicité',
        'Anti-spam',
        'Webmail',
        'Synchronisation sur tous les appareils',
        'Assistance 24/7',
      ],
    },
    {
      id: 2,
      title: 'Email Basic 1+',
      storage: '12 Go',
      emails: 'environ 60 000 emails',
      price: 2.5,
      priceTTC: 3,
      discount: 28,
      oldPrice: 3.5,
      features: [
        '1 boîte email de 12 Go, extensible à tout moment',
        '1 domaine inclus',
        'Sans publicité',
        'Anti-spam',
        'Webmail',
        'Synchronisation sur tous les appareils',
      ],
    },
    {
      id: 3,
      title: 'Email Pro',
      storage: '50 Go',
      emails: 'environ 500 000 emails',
      priceOptions: [5, 10, 20],
      usersOptions: ['1 utilisateur', '2 utilisateurs', '5 utilisateurs'],
      features: [
        '1 boîte email de 50 Go par utilisateur',
        '1 domaine inclus',
        'Sans publicité',
        'Antivirus et anti-spam',
        'Webmail',
        'Synchronisation de vos données sur tous les appareils mobiles',
        'Fonctions de partage pour le travail d\'équipe',
      ],
    },
  ];

  return (
    <div >
      <>
        <div className='container-fluid header-service-email'>
          <img alt='' src='/assets/images/services/TextInfo.png' className='textinfo' /><br />
          <img alt='' src='/assets/images/services/frame91.png' className='frame91' />
          <div className='row'>
            <div className='col-6'><h1>1,50$</h1></div>
            <div className='col-6'><h2>HT/mois<br />
              (1,80$ TTC)</h2></div>
          </div>
          <a className='voirpacks' href='/solutions/MailPro#voirpacks'>Voir les packs ↗</a>

        </div>
      </>
      <div className='about'>
        <div className='row mb-5'>
          <div className='col-md-6'>
            <h1 className="green">Pourquoi choisir V_Cam pour vos emails?</h1>
            <h3>Adresse email basée sur votre domaine</h3>
            <p className='text-muted text-justify'>Nos solutions e-mail vous offrent la liberté de créer une adresse personnalisée sans être limité à une sélection restreinte, comme c’est le cas avec les services gratuits. De plus, nous incluons un nom de domaine pour toute la durée du contrat, vous permettant d’avoir une adresse unique et adaptée à votre activité professionnelle.
              <br /><br />
              Une adresse e-mail associée au nom de votre entreprise renforce la crédibilité de votre marque et facilite la reconnaissance auprès de vos clients. Vous pouvez également configurer plusieurs adresses dédiées à différents besoins, comme contact@votreentreprise.com ou ventes@votreentreprise.com.
            </p>
          </div>
          <div className='col-md-6'>
            <img src='/assets/images/services/Rectangle22.png' alt='' className='rectangle22' />
            <img src='/assets/images/services/Rectangle23.png' alt='' className='rectangle23' />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6 text-center'><img src='/assets/images/services/frame.png' alt='' width={400} className='frame' /></div>
          <div className='col-md-6 mt-5 '>
            <h3>Adresse email basée sur votre domaine</h3>
            <p className='text-muted text-justify'>Avec V_CAM, votre messagerie est parfaitement sécurisée ! Un filtre anti-spam avancé et une protection antivirus de pointe protègent votre boîte email contre les menaces en ligne. Hébergés sur des serveurs conformes aux normes les plus strictes en matière de protection des données, vos emails bénéficient également d’un chiffrement automatique SSL/TLS pour garantir leur confidentialité. Et si vous effacez accidentellement des messages, pas de panique : notre service de récupération vous permet de restaurer votre messagerie jusqu’à 7 jours après la suppression.
            </p>
          </div>
        </div>
      </div>
      <FonctionsPrincipales features={features} />
      <PricingPlans plansData={plansData} />
      <Testimonies />
      <CallToAction title_secondary="Créer une adresse mail<br/> professionnelle" title_tertiary="Simple, facile et rapide.<br/>Créez votre adresse mail professionnelle dès maintenant." />
    </div>
  );
};

export default ServiceEmail;
