import React, { useState, useEffect } from "react";
import Comment from "./comment";
import CommentService from "../../services/Comments"

const CommentSection = ({ article_id }) => {
  const [comments, setComments] = useState([]); 
  const [newComment, setNewComment] = useState({ nom: "", email: "", comment: "" });
  const [error, setError] = useState(null);

  const commentService = new CommentService();

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const data = await commentService.getComments(article_id);
      setComments(Array.isArray(data) ? data : []);
      setError(null);
    } catch (err) {
      setError("Impossible de récupérer les commentaires.");
      console.error(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await commentService.addComment({ ...newComment, article_id });
      setNewComment({ nom: "", email: "", comment: "" }); 
      fetchComments(); 
    } catch (err) {
      console.error("Erreur lors de l'ajout du commentaire :", err);
    }
  };

  const addReply = async (commentId, replyContent) => {
    try {
        const replyData = {
            comment_id: commentId,
            comment: replyContent.content,
            nom: replyContent.nom,
            email: replyContent.email,
            article_id : article_id
          };
          console.log(replyData);
      await commentService.addComment( replyData);
      fetchComments(); 
    } catch (err) {
      console.error("Erreur lors de l'ajout de la réponse :", err);
    }
  };

  return (
    <>
      <Comment comments={comments} error={error} addReply={addReply} />

      <div className="comment-section my-5" style={{ backgroundColor: "#184D47", color: "#fff" }}>
        <h5 className="mb-5">Laisser un commentaire</h5>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label>Votre nom <span className="text-danger">*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder="Nom"
                value={newComment.nom}
                onChange={(e) => setNewComment({ ...newComment, nom: e.target.value })}
                required
              />
            </div>
            <div className="col-md-6">
              <label>Votre adresse Email <span className="text-danger">*</span></label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={newComment.email}
                onChange={(e) => setNewComment({ ...newComment, email: e.target.value })}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <label>Votre commentaire <span className="text-danger">*</span></label>
              <textarea
                className="form-control"
                placeholder="Entrez votre message..."
                rows="4"
                value={newComment.comment}
                onChange={(e) => setNewComment({ ...newComment, comment: e.target.value })}
                required
              ></textarea>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Poster le commentaire</button>
        </form>
      </div>
    </>
  );
};

export default CommentSection;

