import { getApiUrl } from '../environement/env';
import fetchWithApiKey from '../util/fetchWithApiKey';

class Accueil {
    getSolutions = async () => {
        try {
          const apiUrl = getApiUrl();
          const response = await fetchWithApiKey(`${apiUrl}/api/solutions`, {
            method: 'GET', 
          });
          return response; 
        } catch (error) {
          console.error('Erreur lors de la récupération des contacts :', error);
          throw error; 
        }
      };
    getPartenaires = async () => {
        try {
          const apiUrl = getApiUrl();
          const response = await fetchWithApiKey(`${apiUrl}/api/partenaires`, {
            method: 'GET', 
          });
          return response; 
        } catch (error) {
          console.error('Erreur lors de la récupération des partenaire :', error);
          throw error; 
        }
      };
    getTestimonies = async () => {
        try {
          const apiUrl = getApiUrl();
          const response = await fetchWithApiKey(`${apiUrl}/api/temoignages`, {
            method: 'GET', 
          });
          return response; 
        } catch (error) {
          console.error('Erreur lors de la récupération des temoignages :', error);
          throw error; 
        }
      };
    getAbouts = async () => {
        try {
          const apiUrl = getApiUrl();
          const response = await fetchWithApiKey(`${apiUrl}/api/abouts`, {
            method: 'GET', 
          });
          return response; 
        } catch (error) {
          console.error('Erreur lors de la récupération des information :', error);
          throw error; 
        }
      };


}

export default Accueil;