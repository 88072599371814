import React, { useState } from 'react';

const truncateHTML = (htmlString, maxWords) => {
    const div = document.createElement('div'); // Crée un élément temporaire
    div.innerHTML = htmlString; // Assigne le HTML
    const textContent = div.textContent || div.innerText || ""; // Récupère uniquement le texte brut

    const words = textContent.split(" ");
    const isTruncated = words.length > maxWords;

    // Tronque le texte en limitant les mots
    const truncatedText = words.slice(0, maxWords).join(" ");

    return {
        text: truncatedText + (isTruncated ? "..." : ""),
        isTruncated,
    };
};

const RichTextTruncate = ({ description, maxWords }) => {
    const [showFullText, setShowFullText] = useState(false);

    const handleToggle = () => setShowFullText(!showFullText);

    const { text, isTruncated } = truncateHTML(description, maxWords);

    return (
        <p
            className="text-muted"
            style={{ textAlign: 'justify' }}
        >
            <span
                dangerouslySetInnerHTML={{ __html: showFullText ? description : text }}
            />
            {isTruncated && !showFullText && (
                <span
                    onClick={handleToggle}
                    style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
                >
                    Voir plus
                </span>
            )}
            {showFullText && (
                <span
                    onClick={handleToggle}
                    style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
                >
                    Voir moins
                </span>
            )}
        </p>
    );
};

export default RichTextTruncate;
