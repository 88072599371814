import { getApiUrl } from '../environement/env';
import fetchWithApiKey from '../util/fetchWithApiKey';

class Contacts {
  // Récupérer les infos de contact
  getContacts = async () => {
    try {
      const apiUrl = getApiUrl();
      const response = await fetchWithApiKey(`${apiUrl}/api/index-contact`, {
        method: 'GET', // Spécifier la méthode HTTP
      });
      return response; // Renvoie les données reçues
    } catch (error) {
      console.error('Erreur lors de la récupération des contacts :', error);
      throw error; // Propager l'erreur pour une gestion en amont
    }
  };
  getPolitique = async (slug) => {
    try {
      const apiUrl = getApiUrl();
      const response = await fetchWithApiKey(`${apiUrl}/api/politique/${slug}`, {
        method: 'GET', // Spécifier la méthode HTTP
      });
      return response; // Renvoie les données reçues
    } catch (error) {
      console.error('Erreur lors de la récupération des politiques :', error);
      throw error; // Propager l'erreur pour une gestion en amont
    }
  };
  getSupprimer = async (slug) => {
    try {
      const apiUrl = getApiUrl();
      const response = await fetchWithApiKey(`${apiUrl}/api/supprimer_comptes/${slug}`, {
        method: 'GET', // Spécifier la méthode HTTP
      });
      return response; // Renvoie les données reçues
    } catch (error) {
      console.error('Erreur lors de la récupération des supprimer compter :', error);
      throw error; // Propager l'erreur pour une gestion en amont
    }
  };
  getDeconnecter = async (slug) => {
    try {
      const apiUrl = getApiUrl();
      const response = await fetchWithApiKey(`${apiUrl}/api/deconnecters/${slug}`, {
        method: 'GET', // Spécifier la méthode HTTP
      });
      return response; // Renvoie les données reçues
    } catch (error) {
      console.error('Erreur lors de la récupération des deconnecters :', error);
      throw error; // Propager l'erreur pour une gestion en amont
    }
  };

  // Méthode pour soumettre les données du formulaire de contact
  async submitContactForm(formData) {
    try {
      const apiUrl = getApiUrl();
      const response = await fetchWithApiKey(`${apiUrl}/api/contact`, {
        method: 'POST',
        body: formData, // Les données du formulaire
      });
      
      return response; // Retourne la réponse du backend
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
      throw error; // Propager l'erreur pour la gestion dans le composant
    }
  }
  async demandeDevis(formData) {
    try {
      const apiUrl = getApiUrl();
      const response = await fetchWithApiKey(`${apiUrl}/api/demande-devis`, {
        method: 'POST',
        body: JSON.stringify(formData), // Les données du formulaire
      });
      
      return response; // Retourne la réponse du backend
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
      throw error; // Propager l'erreur pour la gestion dans le composant
    }
  }
}

export default Contacts;
