import React from "react";

const Section1 = () => {
  return (
    <div className="container-fluid section1">
      <div className="row d-flex">
        
        {/* Colonne de texte */}
        <div className="col-12 col-sm-12 col-md-6 section1-text">
          <h1><a href="/">Assurer la transformation numérique des entreprises à moindre coûts.</a></h1>
          <h6>
            Fournir des solutions informatiques made in Camroun propriétaire et à la demande, simple et efficace et propre aux réalités locales, accessible sous divers supports (PC, Tablette et SmartPhone) pour une gestion globale et optimale de votre activité, que vous soyez présents ou pas.
            <br/><br/>
            Telle est la mission que nous nous sommes donnée pour assurer la transition numérique des actions du secteur économique au Cameroun et partout ailleurs.
          </h6>
          <p className="mt-3"></p>
        </div>

        {/* Colonne d'image */}
        <div className="col-12 col-sm-12 col-md-6 section1-img">
          <img src="/assets/images/img_section1.png" alt="" className="img-fluid" />
        </div>

      </div>
    </div>
  );
}

export default Section1;
