
import React from 'react';
import Footer from './footer';
import Header from './header';

const Layout = ({ children   }) => {
    return (
        <main>
            
            <Header />
            <div class="main-content">

                {children}
                <Footer/>
            </div>
        </main>
    );
};

export default Layout;
