import React, { Component } from "react";
import Slider from "react-slick";
import Loader from "../../components/Loader";
import Images from "../../components/images";
import NoData from "../../components/nodata";
import Accueil from "../../services/Accueil.js";

class Testimonies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonies: null, 
      loading: true,
      currentSlide: 0, 
    };
    this.sliderRef = React.createRef(); 
    this.AccueilServices = new Accueil(); 
  }

  async componentDidMount() {
    try {
      const data = await this.AccueilServices.getTestimonies(); 
      this.setState({ testimonies: data });
    } catch (error) {
      console.error("Erreur lors de la récupération des témoignages :", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    beforeChange: (current, next) => {
      this.setState({ currentSlide: next });
    },
    arrows: false,
  };

  goToNext = () => {
    this.sliderRef.current.slickNext();
  };

  goToPrev = () => {
    this.sliderRef.current.slickPrev();
  };

  render() {
    const { testimonies, loading } = this.state;

    if (loading) {
      return (
        <div className="m-5">
          <Loader height={100} width={100} />
        </div>
      );
    }

    if (!testimonies || testimonies.length === 0) {
      return ;
    }

    return (
      <div className="container-fluid testimonies mb-4 py-5 px-3">
        <div className="title-testimony mb-4 text-center">
          <h1 className="green">What customers think of us</h1>
          <h4 className="text-white">
            Les clients de V_CAM et leurs résultats sont la meilleure preuve que
            notre méthodologie fonctionne.
          </h4>
        </div>

        <div className="testimonialCarousel">
          <Slider ref={this.sliderRef} {...this.sliderSettings}>
            {testimonies.map((testimony, index) => (
              <div key={index} className="carousel-item">
                <div className="row">
                  <div className="col-md-4">
                    <Images
                    
                      images={testimony.image}
                      alt={testimony.name}
                      className={"img-fluid img-testimonie"}
                    />
                  </div>
                  <div className="col-md-5">
                    <div className="testimony-content text-white">
                      <i className="fa fa-quote-left fa-2x mb-3 green"></i>
                      <p className="mb-3">{testimony.comment}</p>
                      <h5 className="green">{testimony.name}</h5>
                      <p>{testimony.poste}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          <div className="btn-container">
            <button
              className="carousel-control-prev"
              type="button"
              onClick={this.goToPrev}
            >
              <i className="fa fa-arrow-left fa-lg"></i>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              onClick={this.goToNext}
            >
              <i className="fa fa-arrow-right fa-lg"></i>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonies;
