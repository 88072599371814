import React from 'react';

const Solution = ()=>{
    
    return(
            <div className="container text-center solutions mb-4">
                 <h1 className="">Pourquoi <span className="green">choisir nos Solutions ?</span></h1>
                <div className="solution-body">
                    <div className="row mt-3">
                        <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            <div className="card solution-card" >
                                <div className="solution-card-header"><span className="solution-icon"><i class="fa-regular fa-money-bill-wave"></i></span></div>
                                <div class="card-body">
                                    <h1 class="card-title">Des économies au quotidien</h1>
                                    <p class="card-text">Pas d’investissement à l’achaté Fin d’achat de certain matériels.<br/><span class="green">Pour seulement 25XAF HT / mois</span></p>
                                    
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            <div className="card solution-card" >
                                <div className="solution-card-header"><span className="solution-icon"><i class="fa-light fa-stopwatch"></i></span></div>
                                <div class="card-body">
                                    <h1 class="card-title">Utilisable immédiatement</h1>
                                    <p class="card-text">La solution clé en main, vous pouvez commencer la gestion de votre auto-école immédiatement.</p>
                                    
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            <div className="card solution-card" >
                                <div className="solution-card-header"><span className="solution-icon"><i class="fa-regular fa-thumbs-up"></i></span></div>
                                <div class="card-body">
                                    <h1 class="card-title">Sans engagement</h1>
                                    <p class="card-text">Vous pouvez arrêter votre abonnement à n’importe quel moment et récupérer vos données.</p>
                                    
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            <div className="card solution-card" >
                                <div className="solution-card-header"><span className="solution-icon"><i class="fa-light fa-lightbulb-on"></i></span></div>
                                <div class="card-body">
                                    <h1 class="card-title">Solution intuitive</h1>
                                    <p class="card-text">Le site est simple d’utilisation avec une prise en main facile même pour les débutants en informatique.</p>
                                    
                            </div>
                            </div>
                        </div>
                    
                        <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            <div className="card solution-card" >
                                <div className="solution-card-header"><span className="solution-icon"><i class="fa-solid fa-mobile-screen-button"></i></span></div>
                                <div class="card-body">
                                    <h1 class="card-title">Plateforme dématerialisée</h1>
                                    <p class="card-text">Vos données sont utilisables sur n’importe quel support (Ordinateurs, téléphones, tablettes ...).</p>
                                    
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            <div className="card solution-card" >
                                <div className="solution-card-header"><span className="solution-icon"><i class="fa-light fa-shield-check"></i></span></div>
                                <div class="card-body">
                                    <h1 class="card-title">Sécurité</h1>
                                    <p class="card-text">Vos données sont immédiatement sauvegardées. La perte de données n’existe plus.</p>
                                    
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            <div className="card solution-card" >
                                <div className="solution-card-header"><span className="solution-icon"><i class="fa-light fa-flower-tulip"></i></span></div>
                                <div class="card-body">
                                    <h1 class="card-title">Plateforme évolutive</h1>
                                    <p class="card-text">Des mises à jour sont déployées régulièrement sans mettre à contribution l’utilisateur</p>
                                    
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 mb-3">
                            <div className="card solution-card" >
                                <div className="solution-card-header"><span className="solution-icon"><i class="fa-regular fa-user-group"></i></span></div>
                                <div class="card-body">
                                    <h1 class="card-title">Solution pour tous</h1>
                                    <p class="card-text">Gérants, moniteurs et éléves possèdent un compte et des droits qui leurs sont propres!</p>
                                    
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
       
     )
}
export default Solution