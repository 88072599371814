
import {getApiUrl} from '../environement/env';
import fetchWithApiKey from '../util/fetchWithApiKey';

class NosServices {
// Récupérer  les info de contact
getservices = async () => {
    
      try {
        const response = await fetchWithApiKey(`${getApiUrl()}/api/index-service`, {
          method: 'GET', // Spécifier la méthode HTTP
        });
        return  response; // Renvoie les données ici
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      throw error; // Lance l'erreur pour permettre une gestion en aval
    }
};
getstatistique = async () => {
    
      try {
        const response = await fetchWithApiKey(`${getApiUrl()}/api/index-statistique`, {
          method: 'GET', // Spécifier la méthode HTTP
        });
        return  response; // Renvoie les données ici
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      throw error; // Lance l'erreur pour permettre une gestion en aval
    }
};
  

// Méthode pour soumettre les données du formulaire de contact
async newsletters(formData) {
  try {
    console.log('Formulaire soumis :', formData);

    const response = await fetchWithApiKey(`${getApiUrl()}/api/newsletters`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData), 
    });
    return response;
  } catch (error) {
    console.error('Erreur lors de la soumission du formulaire :', error);
    throw error;
  }
}



}


export default NosServices;