import React from 'react';
import './maintenance.css'; 
import { getApiUrl } from '../../environement/env';

const Maintenance = () => {
  const goToBackend = () => {
    window.location.href = getApiUrl(); 
  };

  return (
    <div className="maintenance-container text-center">
      <div className="maintenance-content">
        <div className="maintenance-image">
          <img src="assets/images/maintenance.png" alt="Maintenance" />
        </div>
        <h1 className="text-center">Désolé, nous travaillons actuellement sur le site</h1>
        <p className="text-center">
          Merci pour votre patience. Nous travaillons actuellement sur le site et nous reviendrons rapidement.
        </p>
        <button className="maintenance-button" onClick={goToBackend} alt="se connecter en temps administrateur">
        <i class="fa fa-cog"></i>
        </button>
      </div>
    </div>
  );
};

export default Maintenance;
