import { getApiUrl } from '../environement/env';
import fetchWithApiKey from '../util/fetchWithApiKey';

class CommentService {
    async addComment(postData) {
        try {
            const apiUrl = getApiUrl();
            const response = await fetchWithApiKey(`${apiUrl}/api/comment`, {
              method: 'POST',
              body: JSON.stringify(postData), 
            });
            
            return response; 
          } catch (error) {
            console.error('Erreur lors de l\'ajout du commentaire :', error);
            throw error; 
          }
        
    }

    async getComments(articleId) {
        try {
            const url = `${getApiUrl()}/api/comments/${articleId}`;
            const response = await fetchWithApiKey(url, {
                method: 'GET', 
            });
            return response; 
        } catch (error) {
            console.error('Erreur lors de la récupération des commentaires:', error);
            throw error; 
        }
    }

    async deleteComment(id) {
        const response = await fetchWithApiKey(`${getApiUrl()}/api/comments/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) throw new Error("Erreur lors de la suppression du commentaire");
        return response.json();
    }
}

export default CommentService;
