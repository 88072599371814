import React, { useState, useEffect } from "react";
import NosServices from "../services/NosServices";
import Contacts from "../services/Contacts";
import PopupAlert from "../components/PopupAlert/PopupAlert";

const Footer = () => {
  const [formData, setFormData] = useState({
    email: ''
  });
  const Nosservices = new NosServices();
  const [contacts, setContacts] = useState(null);
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const [alert, setAlert] = useState(null);

  const contactService = new Contacts();
  const triggerAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => setAlert(null), 10000);
  }

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const data = await contactService.getContacts(); // Récupère les contacts
        setContacts(data); // Met à jour les contacts
      } catch (error) {
        console.error("Erreur lors de la récupération des contacts :", error);
      } finally {
        setLoading(false); // Chargement terminé
      }
    };


    fetchContacts();
  }, []);
  if (loading) {
    return <div ></div>;
  }


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const response = await Nosservices.newsletters(formData);
      setFormData({
        email: ''
      });
      triggerAlert('success', 'Vous êtes abonné avec succès !')

    } catch (error) {
      console.log(error)
    } finally {
    }
  };
  return (
    <>
      <footer className="bg-footer text-white py-3 ">
        <div className="container">
          <div className="row">
            {/* Colonne 1 */}
            <div className="col-md-4">
              <h5><img src="/assets/images/logo.png" className="logo" alt="logo" /></h5>
              <p className="about-footer">
                V_CAM est une jeune entreprise passionnée des nouvelles technologies et de son impact dans nos vies. Dirigée par une équipe de jeunes motivés et expérimentés, elle entend apporter des solutions sur mesure , made in Cameroun, aussi efficace que ce qui se fait ailleurs pour optimiser la gestion des PME et vulgariser le numérique à tous et ceux à Petit Prix. </p>
            </div>




            {/* Colonne 3 */}
            <div className="col-md-4">
              <h5 className="title-footer">NEWSLETTER</h5>
              <p className="newsletter-text">L’essentiel de l’information de Visibility_CAM dans votre boîte E-mail.</p>

              <form className="newsletter-form d-flex" onSubmit={handleSubmit}>
                <input type="email" className="form-control" placeholder="Adresse courriel" aria-label="Email" name="email" required value={formData.email}
                  onChange={handleChange} />
                <button type="submit" className="btn btn-newsletter"><i class="fa-solid fa-paper-plane"></i></button>
              </form>
              <h5 className="title-footer py-1">Nos resaux sociaux.</h5>
              <p className="newsletter-text">Restez connectés et suivez toute notre actualité sur</p>
              <div className="social-icons d-flex ms-5"  style={{ marginTop: '-2rem' }} >
                <a href="https://web.facebook.com/profile.php?id=100071858223993" className="text-white me-3">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://youtube.com/@visibilitycam?si=Mz0_EXnJUAhHxJtZ" className="text-white me-3">
                  <i className="fab fa-youtube-square"></i>
                </a>
                <a href="https://www.linkedin.com/company/visibility-cam-sarl/" className="text-white">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>

            </div>

            {/* Colonne 4 */}
            <div className="col-md-4">
              <h5 className="title-footer text-white ps-4">Contacts</h5>
              <ul className="list-unstyled contact">
                <li className="py-1 d-flex  ">
                  <div className="mt-2">
                    <i className="fas fa-map-marker-alt text-white fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0 text-white ms-4">
                      Dschang, Cameroun
                      <br />
                      Douala, Cameroun
                    </p>
                  </div>
                </li>
                <li className="py-1 d-flex  ">
                  <div>
                    <i className="fas fa-clock text-white fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0 text-white ms-4">
                      Lun - Ven : 8h00 - 18h00<br />
                      Sam : 8h00 - 13h00
                    </p>
                  </div>
                </li>
                <li className="py-1 d-flex">
                  <div className="mt-2">
                    <i className="fas fa-phone text-white fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0 text-white ms-4">
                      <a href="tel:+237698613243" className="text-white text-decoration-none">
                        +237 698 61 32 43
                      </a>
                      <br />
                      <a href="tel:+237697049654" className="text-white text-decoration-none">
                        +237 697 04 96 54
                      </a>
                    </p>
                  </div>
                </li>
                <li className="py-1 d-flex">
                  <div>
                    <i className="fas fa-envelope text-white fs-4"></i>
                  </div>
                  <div>
                    <p className="mb-0 text-white ms-4">
                      <a href="mailto:contact@visibilitycam.com" className="text-white text-decoration-none">
                        contact@visibilitycam.com
                      </a>
                      <br />
                      <a href="mailto:demo@visibilitycam.com" className="text-white text-decoration-none">
                        demo@visibilitycam.com
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>




          </div>
          <hr />
          {/* Ligne de Copyright */}
          <div className="row mt-3">
            <div className="col text-center">
              <p>&copy; {new Date().getFullYear()}  V_CAM - Tous droits réservés.</p>
            </div>
          </div>
        </div>
      </footer>
      {alert && (
        <PopupAlert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}
    </>
  );
};

export default Footer;
