import React, { useEffect, useState } from 'react';
import fetchWithApiKey from './fetchWithApiKey';
import { getApiUrl } from '../environement/env';
import Loader from '../components/Loader';
import Maintenance from '../pages/maintenance';

const MaintenanceGuard = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkMaintenance = async () => {
      try {
        const data = await fetchWithApiKey(`${getApiUrl()}/api/check-maintenance`, {
          method: 'GET',
        });
        if (data.maintenance) {
          setIsMaintenance(true);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de la maintenance :', error);
      } finally {
        setLoading(false);
      }
    };

    checkMaintenance();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (isMaintenance) {
    return <Maintenance />;
  }

  return children;  
};

export default MaintenanceGuard;
