import React, { useState, useEffect, useRef } from "react";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import Porfolio from "../../services/Porfolio";
import {getApiUrl} from '../../environement/env';
const Portfolio = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false); // Nouvel état pour suivre le chargement des catégories
  const [selectedCategory, setSelectedCategory] = useState("Toutes");
  const [allItems, setAllItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;
  const service = new Porfolio();
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate("/contact");
  };

  const loadPortfolioItems = async () => {
    if (!categoriesLoaded) return; // Empêche le chargement si les catégories ne sont pas prêtes

    setLoading(true);
    const portfolioItems = await service.getPorfolio();
    const filtered =
      selectedCategory === "Toutes"
        ? portfolioItems
        : portfolioItems.filter((item) => item.category === selectedCategory);

    setAllItems(filtered);
    setVisibleItems(filtered.slice(0, itemsPerPage));
    setPage(1);
    setLoading(false);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      
      try {
        const cat = await service.getCatgorie();
        setCategories(cat);
        setCategoriesLoaded(true); // Indique que les catégories sont prêtes
      } catch (error) {
        console.error("Impossible de récupérer les catégories :", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    loadPortfolioItems(); // Charge les éléments seulement après le chargement des catégories
  }, [selectedCategory, categoriesLoaded]);

  const loadMoreItems = () => {
    const nextPage = page + 1;
    const newVisibleItems = allItems.slice(0, nextPage * itemsPerPage);
    setVisibleItems(newVisibleItems);
    setPage(nextPage);
  };

  const observer = useRef();
  const lastItemRef = (node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && visibleItems.length < allItems.length) {
        loadMoreItems();
      }
    });
    if (node) observer.current.observe(node);
  };

  return (
    <div>
      <div className="big-header-portfolio">
        <div className="header-portfolio container-fluid">
          <div className="rectangle1">
            <div className="rectangle2">
              <div className="rectangle3"></div>
            </div>
          </div>
          <img
            src="assets/images/portfolio/femme.png"
            className="image-porfotio"
            alt="Portfolio header"
          />
          <div className="title-portfolio">
            NOS <span className="green">REALISATIONS</span>
            <div>
              <button
                className="btn contact-button-portfolio"
                onClick={handleContactClick}
              >
                NOUS CONTACTER
              </button>
            </div>
            <div>
              <img
                src="assets/images/portfolio/rectangle.png"
                className="image-rectangle"
                alt=""
              />
              <img
                src="assets/images/portfolio/ping.png"
                className="image-ping"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio-container">
        {/* Liste de catégories */}
        <div className="category-list">
        {categoriesLoaded ? (
          categories.map((category) => (
            <button
              key={category}
              className={`category-button ${
                selectedCategory === category ? "active" : ""
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          )))
         : (
          <Loader />
        )}
        </div>

        {/* Indicateur de chargement */}
        {loading ? (
          <Loader />
        ) : (
          <div className="portfolio-grid">
            {visibleItems.map((item, index) => (
              <div
                key={item.id}
                className="portfolio-item rotate-in"
                ref={index === visibleItems.length - 1 ? lastItemRef : null}
              >
                <div className="portfolio-image-container">
                  <img
                    src={`${getApiUrl()}/storage/${item.imageUrl}`} 
                    alt={item.category}
                    className="portfolio-image"
                  />
                  <div className="portfolio-info">
                    <h3>{item.name}</h3>
                    <p>{item.describe}</p>
                    <a href={item.lien} className="btn btn-more">
                      Voir 
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
