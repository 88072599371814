import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Accueil from "../../services/Accueil";
import Loader from "../../components/Loader";
import Images from "../../components/images";
import NoData from "../../components/nodata";
// Bouton Suivant personnalisé
const NextArrow = ({ onClick }) => {
  return (
    <button
      className="btn btn-section2"
      onClick={onClick}
      style={{ background: "#2085361c", border: "none" , height: "70px" , width: "70px" }}
    >
      <i className="fa-sharp fa-thin fa-arrow-right" style={{  fontSize: "20px" }}></i>
    </button>
  );
};

// Bouton Précédent personnalisé
const PrevArrow = ({ onClick }) => {
  return (
    <button
      className="btn btn-section2 me-2"
      onClick={onClick}
      style={{ background: "#2085361c", border: "none" , height: "70px" , width: "70px"}}
    >
      <i className="fa-sharp fa-thin fa-arrow-left" style={{ fontSize: "20px" }}></i>
    </button>
  );
};

const Section2 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const sliderRef = useRef(null);

  const [solutions, setSolutions] = useState(null); 
  const [loading, setLoading] = useState(true);
  const AccueilServices = new Accueil();
   
  useEffect(() => {
    const fetchSolutions = async () => {
      try {
        const data = await AccueilServices.getSolutions(); 
        setSolutions(data); 
      } catch (error) {
        console.error("Erreur lors de la récupération des solutions :", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchSolutions();
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false, 
  };
  if (loading) {
    return (
      <div className="m-5">
        <Loader height={100} width={100} />
      </div>
    ); 
  }

  if (!solutions) {
    return ; 
  }
  return (
    <div className="container-fluid section2 mb-4">
      <div className="row">
        {/* Colonne gauche avec le titre */}
        <div className="col-md-4 d-flex flex-column mb-4" style={{ height: "100%", paddingTop: "5%" }}>
          <h1 className="green" style={{ fontSize: "40px", fontFamily: "Open sans", fontWeight: "bold" }}>
            Nos services
          </h1>
          <h4 style={{ fontFamily: "Open sans", fontWeight: "bold" }}>
            Plus que des services, Visibility_CAM offre un accompagnement de A à Z
          </h4>
        </div>

        {/* Colonne droite avec le slider */}
        <div className="col-md-8 mb-4">
          <Slider {...sliderSettings} ref={sliderRef}>
            {solutions.map((solution, index) => (
              <div key={index} className="col-12">
                <div className="card section2-card d-flex justify-content-center align-items-center">
                  <Images
                    image={solution.image}
                    className={"section2-card-img-top"}
                    alt={solution.titre}
                    style={{ height: "70px", width: "70px" }}
                  />
                  <div className="card-body text-center">
                    <h4 className="card-title">{solution.titre}</h4>
                    <a href={solution.lien} className="btn green">
                      Voir plus
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          <div className="d-flex justify-content-end align-items-end mb-2 mt-4">
            <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
            <NextArrow onClick={() => sliderRef.current.slickNext()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
