import React, { useEffect, useState } from "react";
import ShareIcons from "./ShareIcons";
import PostNavigation from "./PostNavigation";
import CommentSection from "./CommentSection";
import { useParams } from "react-router-dom";
import Blogs from "../../services/Blogs";
import Loader from "../../components/Loader";
import AuthorInfo from "./AuthorInfo";
import { getApiUrl } from "../../environement/env";

const BlogDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [postnext, setPostNext] = useState(null);
  const [postprec, setPostPrec] = useState(null);
  const [views, setViews] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const BlogService = new Blogs();

  // Utiliser useCallback pour mémoriser la fonction
  const fetchArticle = async () => {
    setLoading(true);
    try {
      const response = await BlogService.getPostById(id);
      if (response && response.article) {
        setPost(response.article);
        setViews(response.views_count || 0);
        setCommentsCount(response.comments_count || 0);
        setPostNext(response.next_article);
        setPostPrec(response.previous_article);
      }
    } catch (error) {
      console.error("Erreur lors du chargement de l'article :", error);
    } finally{
        setLoading(false)
    }
  };

  useEffect(() => {
    fetchArticle();
  }, []);
  if (loading) {
    return (
      <div className="m-5">
        <Loader  />
      </div>
    ); // Affiche un message ou un spinner pendant le chargement
  }

  if (!post) {
    return <p>Aucune donnée disponible.</p>; // Gère le cas où les données sont introuvables
  }
    return (
        <div className="blog-detail container mt-5">
           
        <img src="/assets/images/vecto_image_left_yellow-removebg-preview.PNG" className="img-vector img-vector-blog img2" alt=""/>
        <img src="/assets/images/Vector-green.PNG" className="Vector-green Vector-green-blog img3" alt=""/>
      
    <div className="post-image mb-4">
        <img src={`${getApiUrl()}/storage/${post.image_url}`} alt={post.titre} className="img-detail-blog" />
        <div className="date_published">{post.categorie_article.nom||''}</div>
    </div>

    <h2 className='text-center'>{post.titre||"Lorem Ipsum is simply dummy text of the printing."}</h2>
    <p className="post-content my-4 text-muted"
      dangerouslySetInnerHTML={{
        __html:
          post.description ||
          `
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.
        <br /><br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.
        `,
            }}>
            </p>
    <div className="comments-views-container">
                <span>
                    <i className="fa-light fa-comments"></i>
                    {commentsCount}
                </span>
                <span>
                    <i className="fa-light fa-eye"></i>
                    {views}
                </span>
            </div>
            

            <AuthorInfo date={post.created_at} img={post.profil} author={post.author}/>
    <ShareIcons />

    <PostNavigation postnext={postnext} postprec={postprec} />
    
    <CommentSection article_id={id} />
        </div>
    );
};

export default BlogDetail;
