import React,{useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import Loader from '../../components/Loader';
import Contacts from '../../services/Contacts';

const Politique = () => {
    const [politique, setPolitique] = useState(null); 
    const [loading, setLoading] = useState(true);
    const contactService = new Contacts();
    const {id} = useParams();
    useEffect(() => {
      const fetchContacts = async () => {
        try {
          const data = await contactService.getPolitique(id); 
          setPolitique(data); 
        } catch (error) {
          console.error("Erreur lors de la récupération de politique :", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchContacts();
    }, []);
    if (loading) {
      return (
        <div className="m-5">
          <Loader height={500} width={500} />
        </div>
      ); 
    }
  
    if (!politique) {
      return <p>Aucune donnée disponible.</p>; 
    }
    return (
        <div>
            <div className="container-fluid header-blog text-center py-5">
                <h1>Politique de Confidentialité de {politique.nom}</h1>
                <p>
                    Votre confidentialité est importante pour nous. Veuillez lire notre politique pour comprendre comment nous collectons, utilisons et protégeons vos informations.
                </p>
            </div>

            <div className="container mt-4 mb-4">
                <div className="row">
                    <div className="col-md-10 offset-md-2"  dangerouslySetInnerHTML={{
                __html:
                  politique.politique ||''}}>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Politique;
