import React, { useState, useEffect } from "react";
const TopHeader = () => {

  return (
    // <header className="py-2">
    <div className="container-fluid topheader">
      <div className="row">

        {/* Social Media Icons (20%) */}
        <div className="col-1 py-2 d-flex justify-content-start align-items-center" style={{ backgroundColor: "transparent" }}>
          <a href="https://web.facebook.com/profile.php?id=100071858223993" className="social-icon me-3 green" target="_blank" rel="noreferrer">
            <i className="fab fa-facebook-f"></i> {/* Facebook Icon */}
          </a>
          <a href="https://www.linkedin.com/company/visibility-cam-sarl/" className="social-icon me-3 green" target="_blank" rel="noreferrer">
            <i className="fab fa-linkedin-in"></i> {/* LinkedIn Icon */}
          </a>
          <a href="https://youtube.com/@visibilitycam?si=Mz0_EXnJUAhHxJtZ" className="social-icon me-3 green" target="_blank" rel="noreferrer">
            <i className="fab fa-youtube"></i> {/* YouTube Icon */}
          </a>
        </div>

        {/* Contact Information (80%) */}
        <div className="col-11 py-2 d-flex justify-content-end align-items-center topheader-contact" style={{
          backgroundImage: "url('/assets/images/bg3.png')",
        }} >
          {/* Left-aligned (Phone info) */}
          <div className="d-flex flex-grow-1 justify-content-start ms-4">
            <div className="me-3">
              <i className="far fa-phone"></i> {/* Phone Icon */}
              <span className="ms-2 topheader-size">
                Obtenez un rendez-vous rapide et une assistance technique:
                <a className="ms-2" href="+237698613243"> (+237) 698 613 243</a> {/* Clickable phone number */}
              </span>
            </div>
          </div>


          {/* Right-aligned (Location and Email info) */}
          <div className="d-flex justify-content-end">
            <div className="me-3">
              <i className="fas fa-map-marker-alt"></i> {/* Location Icon */}
              <span className="ms-2"> Foréké-Dschang, Dschang, Cameroun</span>
            </div>
            <span className="me-3">|</span>
            <div className="me-3">
              <i className="far fa-envelope"></i> {/* Email Icon */}
              <span className="ms-2 topheader-size">
                <a className="ms-2" href="mailto:contact@visibilitycam.com">contact@visibilitycam.com</a> {/* Clickable email */}
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
    // </header>
  );
};

export default TopHeader;
