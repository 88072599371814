import React,{useEffect, useState} from 'react';
import './supprimercompte.css'; 
import { useParams } from "react-router-dom";
import Loader from '../../components/Loader';
import Contacts from '../../services/Contacts';
import WordViewer from '../../components/PdfViewer';

const SupprimerCompte = () => {
    const [item, setItem] = useState(null); 
    const [loading, setLoading] = useState(true);
    const contactService = new Contacts();
    const {id} = useParams();
    useEffect(() => {
      const fetchContacts = async () => {
        try {
          const data = await contactService.getSupprimer(id); 
          setItem(data);
        } catch (error) {
          console.error("Erreur lors de la récupération de politique :", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchContacts();
    }, []);
    if (loading) {
      return (
        <div className="m-5">
          <Loader height={500} width={500} />
        </div>
      ); 
    }
  
    if (!item) {
      return <p>Aucune donnée disponible.</p>; 
    }
    return (
        <div>
            {/* En-tête de la page */}
            <div className="container-fluid header-blog text-center py-5">
            <h1>Supprimer votre compte sur {item.nom||''}</h1>
                <p>
                    {item.description ||
                    `Cette page vous explique la procédure pour supprimer définitivement votre compte. Veuillez bien réfléchir avant d'effectuer cette action, car elle est irréversible.`}
                </p>
            </div>

            {/* Contenu de la politique */}
            <div className="container mt-4 mb-4">
                <div className="row">
                    <div className="col-md-10 offset-md-2"  >
                        <WordViewer fileUrl={item.file||''}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupprimerCompte;
