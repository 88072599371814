import React, { useState } from 'react';
import './PricingPlans.css';

const PricingPlans = ({ plansData }) => {
  const [selectedUserOptions, setSelectedUserOptions] = useState(
    plansData.map(() => 0) 
  );

  const handleUserOptionChange = (planIndex, optionIndex) => {
    const newSelections = [...selectedUserOptions];
    newSelections[planIndex] = optionIndex;
    setSelectedUserOptions(newSelections);
  };

  return (
    <div className="container my-5 princing" id="voirpacks">
      <div className="text-center">
        <h1 className="mb-3">Choisissez votre pack email</h1>
        <p>Flexible: ajoutez des boîtes email supplémentaires à tout moment, en fonction de vos besoins !</p>
      </div>
      <div className="row justify-content-start">
        {plansData.map((plan, index) => (
          <div key={plan.id} className="col-md-4">
            <div className={`card pricing-card ${plan.discount ? 'highlighted-card' : ''}`}>
              <div className="card-body">
                <h3 className="card-title">{plan.title}</h3>
                <div className="row">
                  <div className="col-8">
                    <p className="card-text">
                      {plan.storage}
                      <br />
                      {plan.emails}
                    </p>
                  </div>
                  <div className="col-4">
                    {/* Prix dynamique si options disponibles */}
                    <h3 className="price">
                      {plan.priceOptions
                        ? `${plan.priceOptions[selectedUserOptions[index]]}$`
                        : `${plan.price}$`}
                    </h3>
                    <p className="price-info">
                      HT/mois
                      <br />({plan.priceTTC || plan.price}$ TTC)
                    </p>
                  </div>
                </div>
                <hr />
                <button className="btn-outline-primary">Continuer ↗</button>

                {/* Badge de remise (si applicable) */}
                {plan.discount && (
                  <div>
                    <span className="discount-badge">Économisez {plan.discount}%</span>
                    <br />
                    <span className="m-3">{plan.oldPrice} $ HT/mois</span>
                  </div>
                )}

                {/* Options utilisateurs (si présentes) */}
                {plan.usersOptions && (
                  <div className="dropdown mt-2">
                    <select
                      className="form-select"
                      onChange={(e) => handleUserOptionChange(index, e.target.value)}
                    >
                      {plan.usersOptions.map((option, optionIndex) => (
                        <option key={optionIndex} value={optionIndex}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Liste des fonctionnalités */}
                <ul className="feature-list mt-3">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
