import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Accueil from './pages/accueil';
import Portfolio from './pages/porfolio';
import Layout from './layouts/layout';
import Contact from './pages/contact';
import Service from './pages/service';
import Blog from './pages/actublog';
import BlogDetail from './pages/detailBlog/BlogDetail';
import ServiceEmail from './pages/service/email';
import Politique from './pages/politiques';
import SupprimerCompte from './pages/supprimercompte';
import Deconnecter from './pages/deconnecter';
import MaintenanceGuard from './util/MaintenanceGuard';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MaintenanceGuard><Layout><Accueil /></Layout></MaintenanceGuard>} />
        <Route path="/portfolio" element={<MaintenanceGuard><Layout><Portfolio /></Layout></MaintenanceGuard>} />
        <Route path="/blog" element={<MaintenanceGuard><Layout><Blog /></Layout></MaintenanceGuard>} />
        <Route path="/blog/:id" element={<MaintenanceGuard><Layout><BlogDetail /></Layout></MaintenanceGuard>} />
        <Route path="/services" element={<MaintenanceGuard><Layout><Service /></Layout></MaintenanceGuard>} />
        <Route path="/solutions/MailPro" element={<MaintenanceGuard><Layout><ServiceEmail /></Layout></MaintenanceGuard>} />
        <Route path="/contact" element={<MaintenanceGuard><Layout><Contact /></Layout></MaintenanceGuard>} />
        <Route path="/politique/:id" element={<MaintenanceGuard><Layout><Politique /></Layout></MaintenanceGuard>} />
        <Route path="/suprimer-compte/:id" element={<MaintenanceGuard><Layout><SupprimerCompte /></Layout></MaintenanceGuard>} />
        <Route path="/deconnecter/:id" element={<MaintenanceGuard><Layout><Deconnecter /></Layout></MaintenanceGuard>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
