import React from 'react';
import BlogItem from './blogItem';

const BlogList = ({Posts}) => {
   
    console.log('blogList:',Posts)

    return (
        <div>
            {Posts.map(post => (
                <BlogItem key={post.id} post={post} />
            ))}
        </div>
    );
};

export default BlogList;
