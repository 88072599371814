import { getApiUrl } from '../environement/env';
import fetchWithApiKey from '../util/fetchWithApiKey';

class Blogs {
    // Récupérer tous les articles
    async getAllPosts(page = 1, category = 'all') {
        try {
            const url = `${getApiUrl()}/api/blogs?page=${page}&category=${category}`;
            const response = await fetchWithApiKey(url, {
                method: 'GET', // Spécifier la méthode HTTP
            });
            return response; // Convertir en JSON
        } catch (error) {
            console.error('Erreur lors de la récupération des articles:', error);
            throw error; // Relancer l'erreur
        }
    }
    async getCategories() {
        try {
            const url = `${getApiUrl()}/api/index-categories-articles`;
            const response = await fetchWithApiKey(url, {
                method: 'GET', // Spécifier la méthode HTTP
            });
            return response; // Convertir en JSON
        } catch (error) {
            console.error('Erreur lors de la récupération des articles:', error);
            throw error; // Relancer l'erreur
        }
    }

   

    // Récupérer les détails d'un article par son ID
    async getPostById(id) {
        try {
            let sessionId = localStorage.getItem('sessionId');
        
            // Si aucun sessionId n'existe, en générer un nouveau
            if (!sessionId) {
                sessionId =  Math.random().toString(36).substr(2, 20); 
                localStorage.setItem('sessionId', sessionId);  
            }
            const headers = {
                'Authorization': `Bearer ${sessionId}`,  
            };
            const response = await fetchWithApiKey(`${getApiUrl()}/api/blogs/${id}`,{
                method: 'GET', 
                headers: headers,
            });
            return response;
        } catch (error) {
            console.error(`Erreur lors de la récupération de l'article avec l'ID ${id}:`, error);
            throw error;
        }
    }
    

    // Rechercher des articles par mot-clé
    static async searchPosts(keyword) {
        try {
            const response = await fetchWithApiKey(`${getApiUrl()}/blog/posts?search=${keyword}`);
            return response;
        } catch (error) {
            console.error(`Erreur lors de la recherche d'articles avec le mot-clé "${keyword}":`, error);
            throw error;
        }
    }

    // Récupérer les articles récents
    static async getRecentPosts(limit = 5) {
        try {
            const response = await fetchWithApiKey(`${getApiUrl()}/blog/posts?limit=${limit}`);
            return response;
        } catch (error) {
            console.error("Erreur lors de la récupération des articles récents:", error);
            throw error;
        }
    }

}

export default Blogs;
