import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SidebarBlog from './siderbarBlog';
import BlogList from './blogList';
import Blogs from '../../services/Blogs';
import Loader from '../../components/Loader';

const Blog = () => {
    const [datablogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); 
    const [hasMore, setHasMore] = useState(true); 
    const [selectedCategory, setSelectedCategory] = useState('all'); 
    const [loading, setLoading] = useState(false); 

    const BlogService = new Blogs(); 

    const fetchBlogs = async (page = 1, category = 'all') => {
        setLoading(true); 
        try {
            const response = await BlogService.getAllPosts(page, category); 
            const { data, current_page, last_page } = response;
            // Si c'est la première page, on remplace les blogs
            if (page === 1) {
                setBlogs(data);
            } else {
                // Sinon, on ajoute les nouveaux blogs à ceux déjà existants
                setBlogs((prev) => [...prev, ...data]);
            }

            setCurrentPage(current_page); // Met à jour la page actuelle
            setHasMore(current_page < last_page); // Vérifie s'il y a plus de pages
        } catch (error) {
            console.error('Erreur lors de la récupération des articles:', error);
        } finally {
            setLoading(false);
        }
    };

    // Charger les blogs au démarrage ou lors du changement de catégorie
    useEffect(() => {
        fetchBlogs(1, selectedCategory); // Charger les blogs de la catégorie sélectionnée
    }, [selectedCategory]);

    // Charger plus de blogs
    const loadMoreBlogs = () => {
        if (hasMore) {
            fetchBlogs(currentPage + 1, selectedCategory);
        }
    };

    // Filtrer par catégorie
    const filterByCategory = (category) => {
        setSelectedCategory(category); // Met à jour la catégorie sélectionnée
    };


    return (
        <div>
            <div className="container-fluid header-blog">
                <h1>Restés informés</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="blog mt-4 mb-4">
                <div className="row">
                    <div className="col-md-8">
                       
                        {loading ? <Loader/> :  <BlogList Posts={datablogs} />}
                        {hasMore && !loading && (
                            <button onClick={loadMoreBlogs} className="btn w-50 mt-4 btn-blog">
                                Charger Plus de Blogs +
                            </button>
                        )}

                    </div>
                    <div className="col-md-4">
                        <SidebarBlog filterByCategory={filterByCategory} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog;
