import React from "react";

const EnTete = () => {
  return (
    <div className="header-container">
      
      <div className="header-content">
        <img src="assets/images/bg/en-tete.jpg" alt="Entête" className="header-image" />
        
        {/* Contenu superposé */}
        <div className="overlay-content">
          <div className="row">
            <div className="col-md-8 welcome-title">
              <h1 className="welcome-title-1">VISIBILITY_CAM</h1>
              <h3 className="welcome-title-2">Votre Transformation Numérique à petit Prix
              </h3>
              <p className="welcome-message">Nous aidons les entreprises B2B et B2C à atteindre leurs objectifs commerciaux et de transformation digitale en supprimant les barrières financières, technologiques et humaines.
              </p> 
              
              {/* Boutons */}
              <div className="header-buttons">
                <a href="/services" className="btn btn-secondary">Nos Services</a>
                <a href="/contact" className="btn btn-primary">Contactez-nous</a>
              </div>
            </div>
            <div className="col-md-4"> 
              <img src="assets/images/R.png" className="R" alt=""/>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default EnTete;
