import React from 'react';
import {  useNavigate } from 'react-router-dom';

const PostNavigation = ({ postnext, postprec }) => {
    const navigate = useNavigate();

    // Fonction pour rafraîchir la page
    const handleNavigation = (id) => {
        navigate(`/blog/${id}`);
        window.location.reload(); // Force le rechargement de la page
    };

    return (
        <div className="post-navigation d-flex justify-content-between my-5">
            {/* Afficher le poste précédent seulement s'il existe */}
            {postprec && (
                <div className="previous-post text-center">
                    <button 
                        className="btn btn-post" 
                        onClick={() => handleNavigation(postprec.id)}
                    >
                        POSTE PRÉCÉDENT
                    </button>
                    <p className='text-muted'>{postprec.titre}</p>
                </div>
            )}

            {/* Afficher le poste suivant seulement s'il existe */}
            {postnext && (
                <div className="next-post text-center">
                    <button 
                        className="btn btn-post" 
                        onClick={() => handleNavigation(postnext.id)}
                    >
                        POSTE SUIVANT
                    </button>
                    <p className='text-muted'>{postnext.titre}</p>
                </div>
            )}
        </div>
    );
};

export default PostNavigation;
