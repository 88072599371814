import React from 'react';

const Search = () => {
    return (
        <div className="search mb-4">
            <h5 className="mb-3">MOT-CLÉ</h5>
            <div className="input-group">
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Recherchez vos mots-clés..." 
                    aria-label="Rechercher"
                />
                <button className="btn" type="button">
                   Q
                </button>
            </div>
        </div>
    );
};

export default Search;
