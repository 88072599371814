// FonctionsPrincipales.js
import React from 'react';
import './FonctionsPrincipales.css';

const FonctionsPrincipales = ({features}) => {
 
 
  return (
    <div className="container-fluid FonctionsPrincipales" >
    <div className='text-center mb-5'>
      <h1>Fonctions principales</h1>
      <p className="text-success">Gérez et sécurisez vos adresses email avec Visibility_CAM</p>
      </div>
      <div className="row">
        {features.map((feature) => (
          <div className="col-md-4 mb-4" key={feature.id}>
            <div className="card feature-card p-3">
              <div className="icon mb-3">
                <img src='/assets/images/services/group.png' alt=''/>
              </div>
              <h5 className="feature-title">{feature.title}</h5>
              <p className="feature-description text-justify">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FonctionsPrincipales;
