import React from 'react';

const AuthorInfo = ({ author, date,img }) => {
    const formatDate = (createdAt) => {
        const date = new Date(createdAt);
        const options = { day: '2-digit', month: 'long', year: '2-digit' };
        return new Intl.DateTimeFormat('fr-FR', options).format(date);
    };
    return (
        <div className="author-info my-3 d-flex align-items-center">
            <img src={img||'/favicon.png'} alt={author||'Admin'} className="author-image me-3 rounded-circle"  style={{ width: "40px", height: "40px" }} />
            <div>
                <p className="mb-1"><strong>{author||'Admin'}</strong></p>
                <span className="text-muted">{formatDate(date)||''}</span>
            </div>
        </div>
    );
};

export default AuthorInfo;
