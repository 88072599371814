import React from 'react';

const RecentComments = () => {
    const comments = [
        { id: 1, text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.", user: "Nom de l'utilisateur", image: "assets/images/blog/img4.png" },
        { id: 2, text: "Lorem Ipsum is simply dummy text of the printing industry.", user: "Nom de l'utilisateur", image: "assets/images/blog/img4.png" },
        // Ajoutez d'autres commentaires ici
    ];

    return (
        <div className="recent-comments mb-4">
            <h5 className="mb-3">COMMENTAIRES RÉCENTS</h5>
            <ul className="list-unstyled">
                {comments.map(comment => (
                    <li key={comment.id} className="d-flex align-items-center mb-3">
                        <img src={comment.image} alt={comment.user} className="comment-image me-3 mb-3" />
                        <div>
                            <p className="mb-1">{comment.text}</p>
                            <span className="text-muted">{comment.user}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecentComments;
