import React, { useState } from "react";

const Comment = ({ comments, error, addReply }) => {
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyContent, setReplyContent] = useState("");
  const [replyName, setReplyName] = useState("");
  const [replyEmail, setReplyEmail] = useState("");
  const [visibleReplies, setVisibleReplies] = useState({}); 

  const MAX_DEPTH = 4;

  const handleReply = (commentId, depth) => {
    if (depth < MAX_DEPTH) {
      setReplyingTo(commentId);
    } else {
      console.log("Vous ne pouvez pas répondre au-delà de 4 générations.");
    }
  };

  const toggleReplies = (commentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  const submitReply = (e, commentId) => {
    e.preventDefault();
    if (!replyContent.trim() || !replyName.trim() || !replyEmail.trim()) return;

    addReply(commentId, { content: replyContent, nom: replyName, email: replyEmail });

    setReplyContent("");
    setReplyName("");
    setReplyEmail("");
    setReplyingTo(null);
  }; 

  const renderComment = (comment, depth = 1) => (
    <div key={comment.id} className="comment">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-start">
          <h4>
            {comment.nom} <small className="text-muted">({comment.email})</small>
          </h4>
          {depth < MAX_DEPTH && (
          <button
            className="btn btn-link text-muted p-0"
            onClick={() => handleReply(comment.id, depth)}
          >
            <i className="fa-light fa-reply me-1"></i>
            Répondre
          </button>
          )}
        </div>
        <p className="card-text mt-2 ps-5">{comment.comment}</p>

        <div className="d-flex align-items-center">
          {comment.replies?.length > 0 && (
            <button
              className="btn btn-link text-muted p-0"
              onClick={() => toggleReplies(comment.id)}
            >
              <i className="fa-light fa-comments me-1"></i>
              {comment.replies.length} réponse
              {comment.replies.length > 1 ? "s" : ""}
            </button>
          )}
        </div>

        {visibleReplies[comment.id] &&
          comment.replies?.length > 0 && (
            <div className="replies ms-4 border-start ps-3 mt-3">
              {comment.replies.map((reply) => renderComment(reply, depth + 1))}
            </div>
          )}

        {replyingTo === comment.id && (
          <form className="reply-form mt-3" onSubmit={(e) => submitReply(e, comment.id)}>
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Votre nom..."
              value={replyName}
              onChange={(e) => setReplyName(e.target.value)}
              required
            />
            <input
              type="email"
              className="form-control mb-2"
              placeholder="Votre email..."
              value={replyEmail}
              onChange={(e) => setReplyEmail(e.target.value)}
              required
            />
            <textarea
              className="form-control mb-2"
              rows="2"
              placeholder="Écrivez votre réponse..."
              value={replyContent}
              onChange={(e) => setReplyContent(e.target.value)}
              required
            />
            <button type="submit" className="btn btn-success btn-sm">
              Publier la réponse
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-sm ms-2"
              onClick={() => setReplyingTo(null)}
            >
              Annuler
            </button>
          </form>
        )}
      </div>
    </div>
  );

  return (
    <div className="comments-list mt-2 my-3 shadow-sm border rounded p-3">
      {error ? (
        <p className="text-danger">{error}</p>
      ) : comments && comments.length > 0 ? (
        comments.map((comment) => renderComment(comment))
      ) : (
        <p className="text-muted">Aucun commentaire disponible.</p>
      )}
    </div>
  );
};

export default Comment;
