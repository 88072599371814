import React, { useState, useEffect } from "react";
import NosServices from "../../services/NosServices";
import Loader from "../../components/Loader";
import Images from "../../components/images";
import NoData from "../../components/nodata";

const Group565 = () => {
  const [services, setServices] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0); 
  const [rotate, setRotate] = useState(false); 
  const Nosservices = new NosServices();

  
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const data = await Nosservices.getservices();
        setServices(data || []); 
      } catch (error) {
        console.error("Erreur lors de la récupération des services :", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    if (services.length > 0) {
      setRotate(true);
      const timer = setTimeout(() => setRotate(false), 500); 
      return () => clearTimeout(timer); 
    }
  }, [currentIndex, services.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % services.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + services.length) % services.length);
  };

  const handleServiceClick = (index) => {
    setCurrentIndex(index);
  };

  if (loading) {
    return <Loader />;
  }

  if (!services || services.length === 0) {
    return ;
  }

  const currentService = services[currentIndex];

  return (
    <div className="row group565">
      <div className="col-md-8 group559-1">
        {services.map((service, index) => {
          const positionClass = `services${((index - currentIndex + services.length) % services.length) + 1}`;

          return (
            <div
              key={service.id}
              className={`${positionClass} ${index === currentIndex ? "focused" : ""}`}
              onClick={() => handleServiceClick(index)}
            >
              <h5 className={`title-service text-center ${index === currentIndex ? "green" : ""}`}>
                {service.title}
              </h5>
              <span className={`compomemnt11 ${index === currentIndex ? "green" : ""}`}>
                <Images image={service.image_url} alt={service.title} />
              </span>
            </div>
          );
        })}
        <img
          src={`assets/images/services/Ellipse5.png`}
          alt=""
          className={`Ellipse5 ${rotate ? "rotating" : ""}`}
        />
        <span className="eclipse">
          <img src="assets/images/services/Group557.png" alt="" />
        </span>
      </div>

      {currentService && (
        <div className="card group559-2 col-md-4">
          <h2 className="green">{currentService.title}</h2>
          <div className="card-body">
            <p dangerouslySetInnerHTML={{ __html: currentService.description || "" }}></p>
            <div>
              <button
                className="btn me-2"
                onClick={handlePrev}
                style={{
                  background: "#2085361c",
                  border: "none",
                  height: "70px",
                  width: "70px",
                }}
              >
                <i className="fa-sharp fa-thin fa-arrow-left" style={{ fontSize: "20px" }}></i>
              </button>
              <button
                className="btn"
                onClick={handleNext}
                style={{
                  background: "#2085361c",
                  border: "none",
                  height: "70px",
                  width: "70px",
                }}
              >
                <i className="fa-sharp fa-thin fa-arrow-right" style={{ fontSize: "20px" }}></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Group565;
