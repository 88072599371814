// Categories.js
import React,{useState,useEffect} from 'react';
import Loader from '../../components/Loader';
import Blogs from '../../services/Blogs';

const Categories = ({filterByCategory}) => {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const BlogService = new Blogs(); 

    useEffect(() => {
        const fetchCategories = async () => {
            setLoading(true)
            try {
                const response = await BlogService.getCategories();
                setCategories(response);
            } catch (error) {
                console.error('Erreur lors de la récupération des catégories:', error);
            }
            setLoading(false)
        };

        fetchCategories();
    }, []);
 
    return (
        <div className="categories mb-4 mt-4">
            <h5>CATEGORIES</h5>
            <ul className="list-unstyled">
                <li onClick={() => filterByCategory('all')} className="d-flex justify-content-between text-muted">Toutes</li>
            {loading ? (
                <Loader/>
            ) : (
                categories.map((category, index) => (
                    <li key={index} onClick={() => filterByCategory(category.name)} className="d-flex justify-content-between text-muted">
                        <span>{category.name}</span>
                        <span>{category.count}</span>
                    </li>
                )))}
            </ul>
        </div>
    );
};

export default Categories;
