import React from "react";
import Partenaire from "../../components/partenaire";
import CallToAction from "../../components/calltoaction";
import Apropos from "./apropos"
import Testimonies from "./testimonies"
import Approche from "./approche"
import Solution from "./solution"
import Section2 from "./section2"
import Section1 from "./section1"
import EnTete from "./en-tete"

const Accueil = ()=>{
    
    return(
            <div>
                 <EnTete/>
                 <Section1/>
                 <Section2/>
                 <Solution/>
                 <Approche/>
                 <Testimonies/>
                 <Apropos/>
                 <CallToAction title_secondary="Toute entreprise sans le numérique <br/> est vouée à disparaître." title_tertiary="Ne laissez pas cela arriver. Contactez-nous dès maintenant!" />
                <Partenaire/>
            </div>
       
     )
}
export default Accueil