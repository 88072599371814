import React from "react";

const Approche = () => {
  return (
    <div className="container-fluid approche mb-5">
      <div className="approche-title ">
        <h1 className="green approche-title-primary">Notre approche</h1>
        <h1 className="approche-title-secondary">Découvrez notre recette pour répondre à vos besoins </h1>
      </div>
      <div className="approche-body">
        <div className="row">
          <div className="col-md-5 col-sm-12 ">
            <div className="image-">
              <img
                src="assets/images/approches2.png"
                alt="images approches"
              />
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="row mt-2">
              <div className="col-md-6 mb-2 element1">
                <div className="card plan-element" >
                  <div className="plan-element-header"><span className="number-plan left">1</span><span className="green right me-3">👀 </span></div>
                  <div class="card-body">
                    <h1 class="card-title green">Observer
                    </h1>
                    <p class="card-text">Nous commençons par bien comprendre le contexte de chaque client, en étudiant leurs besoins, leurs défis et leur environnement.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2 element2">
                <div className="card plan-element" >
                  <div className="plan-element-header"><span className="number-plan left">2</span><span className="green right me-3">🧐 </span></div>
                  <div class="card-body">
                    <h1 class="card-title green">Analyser
                    </h1>
                    <p class="card-text">Ensuite, nous analysons les informations recueillies pour identifier les problèmes et les meilleures solutions possibles.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2 element3">
                <div className="card plan-element" >
                  <div className="plan-element-header"><span className="number-plan left">3</span><span className="green right me-3">⚙ </span></div>
                  <div class="card-body">
                    <h1 class="card-title green">Solutionner
                    </h1>
                    <p class="card-text">Nous proposons des solutions sur mesure, adaptées aux besoins spécifiques du client, tout en garantissant leur efficacité et leur fiabilité.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2 element4">
                <div className="card plan-element" >
                  <div className="plan-element-header"><span className="number-plan left">4</span><span className="green right me-3">🔄</span></div>
                  <div class="card-body">
                    <h1 class="card-title green"> Suivi
                    </h1>
                    <p class="card-text">Enfin, nous assurons un suivi constant pour nous assurer que les solutions fonctionnent bien et apportent les résultats attendus.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approche;
