import React from 'react';
import { Circles } from 'react-loader-spinner'; 

const Loader = ({ height, width }) => {
    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            width: 'auto'
         }}>
            <Circles
                color="#208536"
                height={height}
                width={width}
                timeout={10000} 
            />
        </div>
    );
};

export default Loader;
