import React from "react";


const Apropos = () => {

  return (
      <div className="apropos">
        <h1 className="apropos-title">
          <span className="green">À propos</span> de nous
        </h1>
        <div className="row">
          <div className="col-md-4 col-sm-12 mb-2" >
            <div className="custom-card ">
              <img src="/assets/images/mission.png" alt="mission" className="card-img-top img-fluid" />
              <div className="card-body custom-body">
                <h5 className="card-title green">Mission</h5>
                <p className="card-text text-justify">
                  Nous accompagnons <b>les entreprises, institutions et organisations dans leur transition numérique, en leur fournissant des solutions technologiques innovantes, personnalisées et à Petit Prix</b>.
                  <br />
                  Notre objectif est simple : rendre le numérique accessible à tous, pas seulement aux grandes entreprises. Nous croyons que chaque structure – qu’il s’agisse d’une PME, d’une startup, d’un particulier ou même d’un organisme public – mérite de profiter des opportunités qu’offre la technologie pour se développer, améliorer ses services et gagner en efficacité.
                  <br />
                  Le numérique n’est pas un luxe. C’est un levier de croissance, de productivité et de compétitivité. Et nous sommes là pour faire en sorte que chacun puisse en tirer profit, quels que soient ses moyens.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-2" >
            <div className="custom-card ">
              <img src="/assets/images/vision.png" alt="mission" className="card-img-top img-fluid" />
              <div className="card-body custom-body">
                <h5 className="card-title green">Vision</h5>
                <p className="card-text text-justify">
                <b>🔹 À moyen terme<br /></b>
                  Nous voulons devenir une entreprise de référence au Cameroun dans le développement logiciel et les services numériques.
                  Notre but est d’aider les entreprises, institutions et organisations à réussir leur transformation digitale, grâce à des solutions fiables, modernes et adaptées à leurs besoins.
                  <br />
                  <b> 🔹 À long terme<br /></b>
                  Nous visons une présence à l’échelle africaine.
                  Nous voulons devenir un acteur majeur du numérique en Afrique, en proposant des solutions qui favorisent un développement technologique inclusif, durable et accessible à tous.
                  Nous croyons que le numérique est un outil puissant de progrès, et nous souhaitons y contribuer activement.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-2" >
            <div className="custom-card ">
              <img src="/assets/images/valeurs.png" alt="valeurs" className="card-img-top img-fluid" />
              <div className="card-body custom-body">
                <h5 className="card-title green">Valeurs</h5>
                <p className="card-text text-justify">
                  🔗 <b>Cohésion</b>   <br />
                  Nous travaillons en équipe, dans un esprit de solidarité et de respect. Chacun apporte sa part, et ensemble, nous allons plus loin.
                  <br />  
                  👂 <b>Écoute</b>   <br />
                  Nous prenons le temps de bien comprendre les besoins de nos clients et de nos collaborateurs, pour apporter des solutions vraiment utiles.
                  <br />   
                  🧠 <b>Compétences</b>   <br />
                  Nous maîtrisons notre métier. Nos développeurs sont bien formés, passionnés, et toujours à jour sur les nouvelles technologies.
                  <br />
                  💡 <b>Innovation </b>  <br />
                  Nous aimons créer, innover et proposer des idées nouvelles. Nos solutions sont modernes, efficaces et adaptées aux défis d’aujourd’hui.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
export default Apropos;
