import React from 'react';
import { getApiUrl } from '../environement/env';

const Image = ({ image, className , alt , style }) => {
    if (!image) {
        console.warn('Image source is missing.');
        return null; 
    }

    return (
        <img 
            src={`${getApiUrl()}/storage/${image}`} 
            className={className} 
            alt = {alt}
            style={style}
        />
    );
};

export default Image;
