import React from 'react';
import { Link } from 'react-router-dom';
import Images from '../../components/images'
import AuthorInfo from '../detailBlog/AuthorInfo';
import RichTextTruncate from '../../util/RichTextTruncate ';

const BlogItem = ({ post }) => {
   
    return (
        <div className="blog-item mb-4">
            <Link to={`/blog/${post.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Images  image={post.image_url} alt={post.titre} className={"img-fluid w-40"}  />
                <div className="mt-3">
                    <span className='date_published'>{post.categorie_article.nom||''}</span>
                    <h5>{post.titre}</h5>
                </div>
            </Link>
            <RichTextTruncate description={post.description} maxWords={100} />
            <div className="comments-views-container">
                <span>
                    <i className="fa-light fa-comments"></i>
                    {post.comments_count}
                </span>
                <span>
                    <i className="fa-light fa-eye"></i>
                    {post.views_count}
                </span>
            </div>

            <AuthorInfo date={post.created_at} img={post.profil} author={post.author}/>
        </div>
    );
};

export default BlogItem;
