import React, { useState, useEffect, useRef } from "react";
import Contacts from "../../services/Contacts";
import Loader from "../../components/Loader";
import NosServices from "../../services/NosServices";
import PopupAlert from "../../components/PopupAlert/PopupAlert";
// import CustomCaptcha from "../../components/Captcha/CustomCaptcha";

function ContactForm() {
  const [formData, setFormData] = useState({
    nom: "",
    secteur_activite: "",
    pays: "",
    email: "",
    telephone: "",
    service_id: "",
    budget: "",
    sujet: "",
    message: "",
    doc: null, // Ajout pour le fichier
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [isVerified, setIsVerified] = useState(false);
  const [alert, setAlert] = useState(null);

  const fileInputRef = useRef(null); // Référence pour le champ fichier

  const contactService = new Contacts();
  const Nosservices = new NosServices();
  const triggerAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => setAlert(null), 10000); 
  }
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const data = await Nosservices.getservices();
        setServices(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des services :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) {
    return (
      <div className="mt-5">
        <Loader width={200} height={200} />
      </div>
    );
  }
  
  // const handleCaptchaVerify = (status) => {
  //   setIsVerified(status); // Met à jour l'état lorsque le CAPTCHA est validé
  // };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "doc") {
      setFormData({ ...formData, doc: files[0] }); // Enregistrer le fichier sélectionné
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click(); // Ouvrir le sélecteur de fichier
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!isVerified) {
    //   setErrorMessage("Veuillez compléter le CAPTCHA avant de soumettre.");
    //   return;
    // }
    setIsSubmitting(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    console.log(formData)
    try {
      const formDataToSubmit = new FormData();
      
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null) { // Ne pas envoyer un champ avec valeur null
          formDataToSubmit.append(key, value);
        }
      });
      const response = await contactService.submitContactForm(formDataToSubmit);
      //  console.log(response);
      if (response?.message === 'Contact message submitted') {
      setSuccessMessage("Votre demande a été envoyée avec succès!");
      triggerAlert('success', 'Votre demande a été envoyée avec succès!')
      setFormData({
        nom: "",
        secteur_activite: "",
        pays: "",
        email: "",
        telephone: "",
        budget: "",
        doc: null,
        sujet: "",
        message: "",
      });
    } else {
      setErrors(response?.errors || {});
    }
    } catch (error) {
      setErrorMessage("Une erreur est survenue. Veuillez réessayer.");
      triggerAlert('error', 'Une erreur est survenue. Veuillez réessayer.')
      
    } finally {
      setIsSubmitting(false); 
    }
  };

  return (
    <>
    <div className="form-contact card mt-4">
      <h2>Lancez-vous</h2>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      {successMessage && <p className="text-success">{successMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-contain col">
            <label htmlFor="name">
              Nom<span className="text-danger">*</span>/Entreprise
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="nom"
              name="nom"
              placeholder="Nom/Entreprise"
              className="form-control"
              value={formData.nom}
              onChange={handleChange}
              required
            />
            {errors.nom && <span className="error">{errors.nom[0]}</span>}
          </div>
          <div className="form-contain col">
            <label htmlFor="v">
              Secteur d’activité <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="secteur_activite"
              name="secteur_activite"
              placeholder="Commerçant "
              className="form-control"
              value={formData.secteur_activite}
              onChange={handleChange}
              required
            />
            {errors.secteur_activite && <span className="error">{errors.secteur_activite[0]}</span>}
          </div>
          <div className="form-contain col">
            <label htmlFor="pays">
              Ville ,Pays <span className="text-danger">*</span>:
            </label>
            <input
              type="pays"
              id="pays"
              name="pays"
              className="form-control"
              placeholder="Dschang , Cameroun"
              value={formData.pays}
              onChange={handleChange}
              required
            />
            {errors.pays && <span className="error">{errors.pays[0]}</span>}
          </div>
          <div className="form-contain col">
            <label htmlFor="email">
              Adresse Email <span className="text-danger">*</span>:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="text@gmail.com"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email[0]}</span>}
          </div>

          <div className="form-contain col">
            <label htmlFor="phone">
              Téléphone <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="telephone"
              name="telephone"
              placeholder="+237xxxxxxxx"
              className="form-control"
              value={formData.telephone}
              onChange={handleChange}
              required
            />
            {errors.telephone && <span className="error">{errors.telephone[0]}</span>}
          </div>
          <div className="form-contain col">
            <label htmlFor="service_id">Services</label>
            <select
              id="service_id"
              name="service_id"
              className="form-control"
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Sélectionnez un service
              </option>
              {Array.isArray(services) && services.length > 0 ? (
                services.map((service) => (
                  <option key={service.id} value={service.id}>
                    {service.title}
                  </option>
                ))
              ) : (
                <option disabled>Aucun service disponible</option>
              )}
            </select>
            {errors.service_id && <span className="error">{errors.service_id[0]}</span>}
          </div>
        </div>
        <div className="form-contain">
            <label htmlFor="budget">
              Budget Prevu <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              id="budget"
              name="budget"
              placeholder="1000000XAF"
              className="form-control"
              value={formData.budget}
              onChange={handleChange}
              required
            />
            {errors.budget && <span className="error">{errors.budget[0]}</span>}
          </div>
        <div className="form-contain">
          <label htmlFor="sujet">Sujet </label>
          <input
            type="sujet"
            id="sujet"
            name="sujet"
            placeholder="sujet"
            className="form-control"
            value={formData.sujet}
            onChange={handleChange}
            required
          />
          {errors.sujet && <span className="error">{errors.sujet[0]}</span>}
        </div>
        <div className="form-contain" style={{ height: "171px" }}>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            placeholder="Entrer you message...."
            className="form-control"
            value={formData.message}
            onChange={handleChange}
            required
          />
          {errors.message && <span className="error">{errors.message[0]}</span>}
        </div>
        <div className="form-contain">
            <label htmlFor="doc">
              Document 
            </label>
            <input
              type="file"
              id="doc"
              name="doc"
              className="form-control"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleChange}
            />
            <button type="button" onClick={handleFileClick}>
              Téléverser le document
            </button>
            {formData.doc && (
              <p className="mt-2">Fichier sélectionné : {formData.doc.name}</p>
            )}
          </div>
          {/* <CustomCaptcha onVerify={handleCaptchaVerify} /> */}
          <button type="submit" className={`btn-submit ${isSubmitting ? 'disabled' : ''}`} disabled={isSubmitting}>
            {isSubmitting ? "Envoi en cours..." : "Envoyer la demande"}
          </button>

      </form>
    </div>
    {alert && (
        <PopupAlert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(null)}
        />
      )}
    </>
  );
}

export default ContactForm;
