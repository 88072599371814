// Sidebar.js
import React from 'react';
import Search from './Search';
import RecentArticles from './RecentArticles';
import RecentComments from './RecentComments';
import Categories from './Categories';

const SidebarBlog = ({filterByCategory}) => {
    return (
        <div className="sidebarblog">
            <Search />
            <RecentArticles />
            <RecentComments />
            <Categories filterByCategory={filterByCategory}/>
        </div>
    );
};

export default SidebarBlog;
