import { getApiUrl } from "../environement/env";
import fetchWithApiKey from '../util/fetchWithApiKey';

class Porfolio {
  // Récupérer les informations de catégorie
  getCatgorie = async () => {
    try {
      
      const data = await fetchWithApiKey(`${getApiUrl()}/api/categorie-portolio`, {
        method: 'GET', // Spécifier la méthode HTTP
      });
      const categories = ["Toutes", ...data.map((item) => item.nom)];

      return categories; // Retourne le tableau transformé
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      throw error; // Lance l'erreur pour permettre une gestion en aval
    }
  };
  getPorfolio = async () => {
    try {
      const response = await fetchWithApiKey(`${getApiUrl()}/api/realisation`, {
        method: 'GET', // Spécifier la méthode HTTP
      });
      return  response; 
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      throw error; // Lance l'erreur pour permettre une gestion en aval
    }
  };

}

export default Porfolio;
