import React from "react";

const CallToAction = ({ title_secondary, title_tertiary }) => {
  return (
      <div className="calltoaction">
        <h6 className="title-primary">Nous Contacter</h6>
        <h2 className="title-secondary"
        dangerouslySetInnerHTML={{ __html: title_secondary }}>
          
        </h2>
        <h3
          className="title-tertiary"
          dangerouslySetInnerHTML={{ __html: title_tertiary }}
        ></h3>
        <div className="button-group">
          <a href="tel:+237698613243" className="btn btn-call">
            Appeler
          </a>
          <a href="mailto:contact@visibilitycam.com" className="btn btn-email">
            Ecrire
          </a>
        </div>
      </div>
  );
};

export default CallToAction;
