import React, { useEffect, useState } from "react";
import { NavLink,useLocation } from "react-router-dom";
import TopHeader from "./topheader";

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [startX, setStartX] = useState(null);
  const location = useLocation();
  const isActives = ![
    "/solutions/AppWeb",
    "/solutions/AppMobile",
    "/solutions/MailPro"
  ].includes(location.pathname);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // Fonction pour afficher/masquer le sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Fonction de détection du scroll pour fixer le header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Détecter le mouvement de swipe pour ouvrir/fermer le sidebar
  useEffect(() => {
    const handleTouchStart = (e) => {
      setStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
      if (!startX) return;

      const currentX = e.touches[0].clientX;
      const diffX = startX - currentX;

      // Swipe vers la gauche pour ouvrir
      // if (diffX > 50) {
      //   setIsSidebarOpen(true);
      // }

      // Swipe vers la droite pour fermer
      if (diffX < -50) {
        setIsSidebarOpen(false);
      }
    };

    const handleTouchEnd = () => {
      setStartX(null);
    };

    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [startX]);

  return (
    <div>
      <TopHeader />
      <header className={`header ${isFixed ? "fixed" : ""}`}>
        <div className="container-fluid">
          <div className="row">
            {/* Logo Section */}
            <div
              className={`col-3 d-flex justify-content-center align-items-center   ${isFixed ? "mt3" : `${isActives ? "" : "div-logo"}`}`}
            >
              <img src="/assets/images/logo.png" className="logo logo-harder" alt="logo" />
            </div>

            {/* Navbar Section */}
            <div className={`col-9 navbar navbar-expand-lg  ${isActives ? "" : "div-nav"} ${isFixed ? "navigate" : ""}`}>
              {!isSidebarOpen && (
                <div className="collapse navbar-collapse">
                  <ul className="navbar-nav ms-auto w-100">
                    <li className="nav-item me-3">
                      <NavLink
                        to="/"
                        className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")}
                        data-key="t-dashboard"
                      >
                        V_CAM
                      <br/><img src="/assets/images/line-shape-3.png" className="line-shape-3" alt=""/>
                      </NavLink>
                    </li>
                    <li
                        className={`nav-item dropdown me-3 ${isDropdownOpen ? "show" : ""}`}
                        onMouseEnter={() => setIsDropdownOpen(true)}
                        onMouseLeave={() => setIsDropdownOpen(false)}
                      >
                        <a
                          href="#"
                          className={`nav-link dropdown-toggle ${isActives ? "" : "green"} `}
                          data-bs-toggle="dropdown"
                          aria-expanded={isDropdownOpen}
                          data-key="t-solutions"
                          onClick={(e) => e.preventDefault()} // Empêche la redirection
                        >
                          Nos Solutions
                        </a>
                         

                        {/* Sous-menu */}
                        <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
                          <li>
                            <NavLink to="/solutions/AppWeb" className="dropdown-item">
                              Nos Apps Web 

                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/solutions/AppMobile" className="dropdown-item">
                              Nos Apps Mobile

                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/solutions/MailPro" className="dropdown-item">
                              E-mails Pro
                            </NavLink>
                          </li>
                         
                        </ul>
                      </li>

                    <li className="nav-item me-3">
                      <NavLink
                        to="/services"
                        className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")}
                        data-key="t-services"
                      >
                        Nos Services
                      <br/><img src="/assets/images/line-shape-3.png" className="line-shape-3" alt=""/>
                      </NavLink>
                    </li>
                    <li className="nav-item me-3">
                      <NavLink
                        to="/portfolio"
                        className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")}
                        data-key="t-portfolio"
                      >
                        Portfolio
                      <br/><img src="/assets/images/line-shape-3.png" className="line-shape-3" alt=""/>
                      </NavLink>
                    </li>
                    <li className="nav-item me-3">
                      <NavLink
                        to="/blog"
                        className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")}
                        data-key="t-blog"
                      >
                        ActuBlog
                      <br/><img src="/assets/images/line-shape-3.png" className="line-shape-3" alt=""/>
                      </NavLink>
                    </li>
                    <li className="nav-item me-3">
                      <NavLink
                        to="/contact"
                        className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")}
                        data-key="t-contact"
                      >
                        Contacts
                      <br/><img src="/assets/images/line-shape-3.png" className="line-shape-3" alt=""/>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
              {/* Toggle Button */}
              <span className={`btn toggle-btn ${isFixed ? "navigate" : ""}`} onClick={toggleSidebar}>
                <i className={`${isSidebarOpen ? "fa-regular fa-circle-xmark" : "fa-solid fa-bars-staggered"}`}></i>
              </span>
            </div>
          </div>
        </div>
        {/* Sidebar */}
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <ul className="sidebar-nav">
            <li className="nav-item">
              <NavLink to="/" className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")} onClick={toggleSidebar}>
                V_CAM
              </NavLink>
            </li>
            <li
              className={`nav-item dropdown me-3 ${isDropdownOpen ? "show" : ""}`}
              onMouseEnter={() => setIsDropdownOpen(true)}
              onMouseLeave={() => setIsDropdownOpen(false)}
            >
              <a
                href="#"
                className={`nav-link dropdown-toggle ${isActives ? "" : "green"} `}
                data-bs-toggle="dropdown"
                aria-expanded={isDropdownOpen}
                data-key="t-solutions"
                onClick={(e) => e.preventDefault()} // Empêche la redirection
              >
                Nos Solutions
              </a>
                

              {/* Sous-menu */}
              <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
                <li>
                  <NavLink to="/solutions/AppWeb" className="dropdown-item" >
                    Nos Apps Web 

                  </NavLink>
                </li>
                <li>
                  <NavLink to="/solutions/AppMobile" className="dropdown-item">
                    Nos Apps Mobile

                  </NavLink>
                </li>
                <li>
                  <NavLink to="/solutions/MailPro" className="dropdown-item">
                    E-mails Pro
                  </NavLink>
                </li>
                
              </ul>
            </li>
            <li className="nav-item">
              <NavLink to="/services" className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")} onClick={toggleSidebar}>
              Nos Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/portfolio" className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")} onClick={toggleSidebar}>
                Portfolio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/blog" className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")} onClick={toggleSidebar}>
                ActuBlog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/contact" className={({ isActive }) => (isActive ? "nav-link green" : "nav-link")} onClick={toggleSidebar}>
                Contacts
              </NavLink>
            </li>
            {/* Add other NavLinks here */}
        </ul>
        </div>
      </header>

      {/* Overlay */}
      {isSidebarOpen && <div className="overlay" onClick={toggleSidebar}></div>}
    </div>
  );
};

export default Header;
