import React from "react";
import Group565 from "./group565";
import Statistique from "../../components/statistique";
import Devis from "../../components/devis";

const Service = () => {
    
  
    return (
      <div>
        <div className="header-service">
        <div className="row">
          <div className="col-2">
            <img src="assets/images/vecto_image_left_yellow-removebg-preview.PNG" className="vecto_image_left_yellow" alt=""/>
          </div>
          <div className="col-10">
          <div className="row">
          <div className="col-md-5">
          <img src="assets/images/services/Frame595.png" className="Frame595" alt=""/>
            <h5 className="green Frame596 mb-4">Transformation Numérique
            ( Logiciels pour PME)
            </h5>
            <p className="text-jsutify">
            Dans le monde numérique d’aujourd’hui, tout entreprise qui réussit fait des investissements importants dans les logiciels. Ce n’est pas négociable. Cependant, les propri`taires d’entreprises trouvent que le vaste choix de solutions standards et personnalisées est déroutant, difficile à naviguer et coûteux s’ils font un mauvais choix.

            <br/><br/>
            Les meilleurs résultats en termes de croissance du chiffre d’affaire et dÉamélioration de l’efficacité sont obtenus grâce à des solutions logiciels adaptées au marché, axées sur les personnes et intégrées de manière transparente à l’ensemble de l’entreprise.
            </p>
          </div>
          <div className="col-md-5">
            <img src="assets/images/services/images1.png" className="images1" alt=""/>
          </div>
          </div>
          </div>
        </div>
        </div>
        <Group565/>
        <Statistique/>
        <div className="benefits">
          <h1 className="text-center">Nos  <span className="green"> avantages</span></h1>
          <div className="row mb-4">
            <div className="col-md-2 card"><span className="icon-benefits"> <img src="assets/images/services/tdesign_money.png" className="tdesign_money" alt=""/></span>
              <h6 className="mt-3 text-center">Un interlocuteur unique</h6>
            </div>
            <div className="col-md-2 card"><span className="icon-benefits"> <img src="assets/images/services/tdesign_money.png" className="tdesign_money" alt=""/></span>
              <h6 className="mt-3 text-center">Solution sur mesure é</h6>
            </div>
            <div className="col-md-2 card"><span className="icon-benefits"> <img src="assets/images/services/tdesign_money.png" className="tdesign_money" alt=""/></span>
              <h6 className="mt-3 text-center">Proximité</h6>
            </div>
            <div className="col-md-2 card"><span className="icon-benefits"> <img src="assets/images/services/tdesign_money.png" className="tdesign_money" alt=""/></span>
              <h6 className="mt-3 text-center">Réactivité</h6>
            </div>
            <div className="col-md-2 card"><span className="icon-benefits"> <img src="assets/images/services/tdesign_money.png" className="tdesign_money" alt=""/></span>
              <h6 className="mt-3 text-center">Professionnalisme </h6>
            </div>
          </div>
        </div>
          <Devis/>
      </div>
    );
  };
  
  export default Service;