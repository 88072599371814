import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Accueil from "../services/Accueil";
import Loader from "./Loader";
import Images from "./images";
import NoData from "./nodata";
import Image from "./images";

// Bouton Suivant personnalisé
const NextArrow = ({ onClick }) => {
  return (
    <button
      className="custom-arrow next-arrow"
      onClick={onClick}
      style={{ background: "#f5cd195e", border: "none" }}
    >
      <i className="fa-sharp fa-thin fa-arrow-right" style={{ color: "white", fontSize: "40px" }}></i>
    </button>
  );
};

// Bouton Précédent personnalisé
const PrevArrow = ({ onClick }) => {
  return (
    <button
      className="custom-arrow prev-arrow"
      onClick={onClick}
      style={{ background: "#f5cd195e", border: "none" }}
    >
      <i className="fa-sharp fa-thin fa-arrow-left" style={{ color: "white", fontSize: "40px" }}></i>
    </button>
  );
};

const Partenaire = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [partenaires, setPartenaires] = useState([]); // Initialisation avec un tableau vide
  const [loading, setLoading] = useState(true);
  const sliderRef = React.useRef(null);
  const AccueilServices = new Accueil();

  useEffect(() => {
    const fetchPartenaires = async () => {
      try {
        const data = await AccueilServices.getPartenaires();
        setPartenaires(data || []); // Gérer le cas où data est null
      } catch (error) {
        console.error("Erreur lors de la récupération des partenaires :", error);
      } finally {
        setLoading(false); // Fin du chargement
      }
    };

    fetchPartenaires();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
  };

  if (loading) {
    return (
      <div className="m-5">
        <Loader height={100} width={100} />
      </div>
    );
  }

  if (!partenaires.length) {
    return ;
  }

  return (
    <div className="container-fluid partenaire py-4">
      <div className="row">
        <div className="col-12 text-center mb-4">
          <h1 className="partenaire-title">
            Nous leur apportons <span className="green">du BONHEUR</span>
          </h1>
        </div>
      </div>

      <div className="row justify-content-center px-5 ms-2 partenaire-body">
        {isMobile ? (
          <>
            {/* Slider pour mobile */}
            <Slider {...sliderSettings} ref={sliderRef}>
              {partenaires.map((partenaire, index) => (
                <div key={index} className="d-flex flex-column justify-content-center align-items-center mb-4">
                  <a href={partenaire.link} target="_blank" rel="noopener noreferrer">
                    <Image
                      image={partenaire.logo}
                      alt={partenaire.nom}
                      className="img-fluid partenaire-logo"
                    />
                  </a>
                  <p className="text-center mt-2 partenaire-name">{partenaire.nom}</p>
                </div>
              ))}
            </Slider>
          </>
        ) : (
          <>
            {/* Grille pour desktop */}
            {partenaires.map((partenaire, index) => (
              <div key={index} className="col-md-3 d-flex flex-column justify-content-center align-items-center mb-4">
                <a href={partenaire.link} target="_blank" rel="noopener noreferrer">
                  <Image
                    image={partenaire.logo}
                    alt={partenaire.nom}
                    className={"img-fluid partenaire-logo"}
                  />
                </a>
                <p className="text-center mt-2 partenaire-name">{partenaire.nom}</p>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Partenaire;
