import React, { useState, useEffect } from 'react';
import NosServices from '../services/NosServices';
import Loader from './Loader';
import NoData from './nodata';

const Statistique = () => {
    const [statsData, setStatsData] = useState([]); 
    const [counters, setCounters] = useState([]);
    const [loading, setLoading] = useState(true);

    const Nosservices = new NosServices();

    useEffect(() => {
        
        const fetchServices = async () => {
            try {
                const data = await Nosservices.getstatistique();
                
                if (Array.isArray(data)) {
                    setStatsData(data);
                    setCounters(data.map(() => 0)); 
                } else {
                    setStatsData([]);
                }
            } catch (error) {
                setStatsData([]); 
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    useEffect(() => {
        if (statsData.length > 0) {
            statsData.forEach((stat, index) => {
                let start = 0;
                const end = stat.count;
                const duration = 2000; 
                const incrementTime = Math.floor(duration / end);

                const timer = setInterval(() => {
                    start += 1;
                    setCounters(prevCounters => {
                        const newCounters = [...prevCounters];
                        newCounters[index] = start;
                        return newCounters;
                    });
                    if (start >= end) clearInterval(timer);
                }, incrementTime);

                return () => clearInterval(timer); 
            });
        }
    }, [statsData]);

    if (loading) {
        return <Loader/> ;
    }

    if (statsData.length === 0) {
        return ;
    }

    return (
        <div className='row statistique'>
            {statsData.map((stat, index) => (
                <div key={stat.id} className='col-md-3 card mb-3'>
                    <h1 className='green'>+{counters[index]}</h1>
                    <h4>{stat.label}</h4>
                </div>
            ))}
        </div>
    );
};

export default Statistique;
