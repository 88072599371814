import React from 'react';

const RecentArticles = () => {
    const articles = [
        { id: 1, title: "Making Inroads in the coworking industry", date: "03 Juin 2024", image: "assets/images/blog/img.png" },
        { id: 2, title: "Exploring Remote Work Benefits", date: "10 Juin 2024", image: "assets/images/blog/img2.png" },
        { id: 3, title: "Latest Trends in Office Spaces", date: "15 Juin 2024", image: "assets/images/blog/img3.png" },
        { id: 4, title: "Latest Trends in Office Spaces", date: "15 Juin 2024", image: "assets/images/blog/img3.png" },
        // Ajoutez d'autres articles ici
    ];

    return (
        <div className="recent-articles mb-4">
            <h5 className="mb-3">ARTICLES RÉCENTS</h5>
            <ul className="list-unstyled">
                {articles.map(article => (
                    <li key={article.id} className="d-flex align-items-center mb-3">
                        <img src={article.image} alt={article.title} className="article-image me-3 mb-3" />
                        <div>
                            <p className="mb-1">{article.title}</p>
                            <span className="text-muted">{article.date}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RecentArticles;
