import React, { useEffect, useState } from "react";
import ContactForm from "./formcontact";
import GoogleMapEmbed from "../../components/map";
import Contacts from "../../services/Contacts";
import Loader from "../../components/Loader";
import NoData from "../../components/nodata";
const Contact = () => {
  const [contacts, setContacts] = useState(null); // Initialisez à `null`
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const contactService = new Contacts();

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const data = await contactService.getContacts(); // Récupère les contacts
        setContacts(data); // Met à jour les contacts
      } catch (error) {
        console.error("Erreur lors de la récupération des contacts :", error);
      } finally {
        setLoading(false); // Chargement terminé
      }
    };

    fetchContacts();
  }, []);
  if (loading) {
    return (
      <div className="m-5">
        <Loader height={500} width={500} />
      </div>
    );
  }


  return (
    <div className="container-fluid">
      <div className="banner-container contact mb-4 ">
        <img
          src="assets/images/vecto_image_left_yellow-removebg-preview.png"
          className="img-vector"
          alt=""
        />
        <div className="contact-title text-center">
          <img src="assets/images/services/devenezclients.png" alt="" />
        </div>
        <div className="row custom-gutters">
          <div className="col-md-4 col-sm-12 mt-3">
            <h4 className="mb-4">
              <b>Travaillez avec nous</b>
            </h4>
            <p className="font-size-contact text-justify"
            >
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.
              <br /><br />
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.
            </p>
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="row justify-content-center">
              <div className="card col-md-4 contact-card ">
                <div className="contact-card-header">
                  <span className="contact-icon">
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                </div>
                <div className="card-body mt-3">
                  <h4 className="text-center font-size-contact">Foréké-Dschang, Cameroun </h4>
                  <h4 className="text-center font-size-contact">Douala, Cameroun </h4>
                </div>
              </div>
              <div className="card col-md-4 contact-card ">
                <div className="contact-card-header">
                  <span className="contact-icon">
                    <i className="far fa-phone"></i>
                  </span>
                </div>
                <div className="card-body">
                  <h4 className="text-center font-size-contact" >
                    <a href="tel:+237 698 61 32 43">
                      +237 698 61 32 43
                    </a>
                  </h4>
                  <h4 className="text-center font-size-contact">
                    <a href="tel:+237 697 04 96 54">
                      +237 697 04 96 54

                    </a>
                  </h4>
                  <h4 className="text-center font-size-contact">
                    <a href={`tel: "+237 698765432"}`}>
                      +237 698 76 54 32
                    </a>
                  </h4>
                </div>
              </div>
              <div className="card col-md-4 contact-card ">
                <div className="contact-card-header">
                  <span className="contact-icon">
                    <i className="far fa-envelope"></i>
                  </span>
                </div>
                <div className="card-body">
                  <h5 className="text-center font-size-contact">
                    <a className="" href={`mailto: "contact@visibilitycam.com"`}>
                      contact@visibilitycam.com
                    </a>
                  </h5>
                  <h5 className="text-center font-size-contact">
                    <a href={`mailto:"demo@visibilitycam.com"`}>
                      demo@visibilitycam.com
                    </a>
                  </h5>
                  <h5 className="text-center font-size-contact">
                    <a href={`mailto: "support@visibilitycam.com"`}>
                      support@visibilitycam.com
                    </a>
                  </h5>
                </div>
              </div>
              <div className="card col-md-4 contact-card ">
                <div className="contact-card-header">
                  <span className="contact-icon">
                    <i class="fa-regular fa-globe-pointer"></i>
                  </span>
                </div>
                <div className="card-body text-center">
                  <h4 className="mt-2" style={{ 'fontSize': '30px' }}>
                    <a
                      href={` "https://web.facebook.com/profile.php?id=100071858223993"`}
                      className="social-icon me-3 text-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                      {/* Facebook Icon */}
                    </a><br />
                    <a
                      href={`"https://www.linkedin.com/company/visibility-cam-sarl/"`}
                      className="social-icon me-3 text-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin-in"></i>
                      {/* LinkedIn Icon */}
                    </a><br />
                    <a
                      href="https://youtube.com/@visibilitycam?si=Mz0_EXnJUAhHxJtZ"
                      className="social-icon me-3 text-danger"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-youtube"></i>
                      {/* YouTube Icon */}
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>

      <div style={{ width: "100%", height: "400px" }}>
        <iframe
          title="Google Map Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3361.602827102071!2d10.0478595!3d5.4377628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x105fb10e4e87dc57%3A0x6c9e9dd7f92c6521!2sVisibility_Cam%20SARL!5e1!3m2!1sfr!2scm!4v1743948139977!5m2!1sfr!2scm"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
