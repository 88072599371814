const fetchWithApiKey = async (url, options = {}) => {
  const apiKey = process.env.REACT_APP_BOT_API_KEY;
  
  const defaultHeaders = options.body instanceof FormData ? {} : {
    'Content-Type': 'application/json',
  };
  
  const updatedOptions = {
    ...options,
    headers: {
      ...defaultHeaders,
      'api_key_bot': apiKey,
      ...(options.headers || {}),
    },
  };

  try {
    const response = await fetch(url, updatedOptions);
    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(
        `Erreur HTTP : ${response.status} - ${response.statusText}`,
        { cause: errorResponse }
      );
    }
    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la requête fetch :', error);
    return error.cause || null; 
  }
};

export default fetchWithApiKey;
